@import '~@getvim/components-base-css/src/bookmd-import';

//Big search input
.big-search-input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #fff;
  border: 1px solid @bookmd-providers-light-grey;
  border-radius: 6px;
  padding: 0 45px 0 20px;
  font-size: 16px;
  color: @bookmd-text-dark-color;
  margin: 30px 0;
  position: relative;

  & + .search-inner-icon {
    right: 30px;
    font-size: 20px;
    color: @bookmd-text-color;
    z-index: 500;
    cursor: pointer;
    .vertical-align-middle(absolute);
  }

  .btn {
    .search-inner-icon {
      position: relative;
      vertical-align: -2px;
      padding: 0 5px 0 3px;
    }
  }

  .validation-message {
    margin-top: 12px;
  }

  & ~ .clear-input-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 11px;
    width: 48px;
    height: 48px;
    line-height: 52px;
    text-align: center;
    color: @bookmd-text-dark-color;
    z-index: 10;
    cursor: pointer;
  }

  &.multiple-inputs {
    padding: 0;

    .clean-input,
    .bootstrap-typeahead-input-main {
      border-bottom: none;
      border-right: 1px solid @bookmd-border-color !important;
      height: 48px;
      margin: 0;
      padding: 0 15px;
      font-size: 16px;

      & + label {
        left: 15px;
        height: 50px;
        line-height: 50px;

        &.input-active {
          height: auto;
          width: auto;
          line-height: normal;
          top: -7px;
          display: inline-block;
          box-shadow: 0 -7px 0 #fff inset;
          padding: 0 5px;
          margin-left: -5px;
          z-index: 2;
        }
      }

      &:active,
      &:focus {
        & + label {
          height: auto;
          width: auto;
          line-height: normal;
          top: -7px;
          display: inline-block;
          box-shadow: 0 -7px 0 #fff inset;
          padding: 0 5px;
          margin-left: -5px;
          z-index: 2;
        }
      }

      &.last-input {
        border-right: none !important;
        padding: 0 45px 0 15px;
      }

      // TODO: Remove the comment after updating the typeahead version
      //&::placeholder {
      //  font-size: 0;
      //}
    }

    .bootstrap-typeahead-input-main {
      border-width: 0;
      box-shadow: none;

      &:focus {
      }
    }

    &.no-right-border {
      .Select.clean-input {
        border-right: none !important;
      }
    }

    input[type='text']::-ms-clear {
      display: none;
    }

    & > .form-group > div.clean-input.Select {
      border-right: none !important;

      .Select-arrow {
        display: none;
      }
    }

    &.show-arrow {
      & > .form-group > div.clean-input.Select {
        .Select-arrow {
          display: inline-block !important;
        }
      }
    }

    &.hide-arrow {
      .Select-arrow-zone {
        display: none !important;
      }

      & > .form-group > div.clean-input.Select {
      }
    }

    &.right-btn-padding {
      padding-right: @big-select-right-btn-width;

      .select-right-btn {
        position: absolute;
        right: 0;
        top: 0;
        width: @big-select-right-btn-width;
        min-width: 0;
        max-width: 100%;
        border-radius: 0 4px 4px 0;
        border-width: 0 0 0 1px;

        &:active,
        &:focus {
          border-color: @bookmd-border-color;
        }
      }
    }

    &.left-search-icon {
      .Select.clean-input,
      .bootstrap-typeahead-input-main {
        padding: 0 15px 0 45px;
      }

      & + .search-inner-icon {
        right: auto;
        left: 15px;
        font-size: 20px;
        color: @bookmd-text-color;
        z-index: 10;
        cursor: pointer;
        .vertical-align-middle(absolute);
      }

      .Select-menu-outer {
        margin-left: -45px;
      }

      & ~ label.typeahead-label {
        position: absolute;
        top: 0;
        left: 45px;
        line-height: 50px;
        font-weight: normal;
        font-size: 16px;
        display: inline-block;
        padding: 0 35px 0 45px;
        margin-left: -40px;
        max-width: 100%;
        .three-dots();
        .transition(all 100ms ease-out);

        &.input-active {
          height: auto;
          width: auto;
          line-height: normal;
          top: -7px;
          margin-left: -2px;
          z-index: 2;
          background: #fff;
          font-size: 11px;
          padding: 0 5px;
          color: @bookmd-text-dark-color;
        }
      }
    }

    &.right-clear-icon {
      .bootstrap-typeahead-input-main {
        padding-right: 35px;
      }
    }

    .clean-input.Select {
      & > .Select-control {
        margin-top: -1px;
        font-size: 16px;
        color: @bookmd-main-color;
        padding: 0;
        border-right: none;
        border-bottom: none;
        line-height: 48px;

        .Select-value {
          line-height: 52px;
          padding: 0;

          .Select-value-label {
            font-weight: @medium;
            color: @bookmd-text-dark-color;
          }
        }

        .Select-arrow:after {
          vertical-align: -2px;
        }

        &:active,
        &:focus {
          border-bottom: none !important;
        }
      }

      &.grey-select {
        border-radius: 6px 0 0 6px !important;
        height: 48px;
        margin: 1px 0 0 0;

        & > .Select-control {
          //box-shadow: -15px 0 0 @bookmd-background-color-1, 15px 0 0 @bookmd-background-color-1;
          //border-radius: 6px 0 0 6px !important;

          .Select-value {
            .Select-value-label {
              font-weight: @semibold;
              color: @bookmd-text-color;
            }
          }
        }
      }

      &:focus,
      &.is-focused,
      &:active {
        border-bottom: none !important;

        .Select-control,
        .Select-input {
          background: none;
        }
      }
    }

    .Select--single > .Select-control .Select-value {
    }

    .clean-input.Select--single.has-value {
      //padding: 0;
    }

    .Select-input {
      height: 50px;
    }

    .Select-placeholder {
      line-height: 52px;
      font-size: 16px;
      padding: 0;
    }

    .big-search-btn-padding {
      padding: 0 138px 0 0;
    }
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    vertical-align: 1px;
  }

  .clean-input.Select .Select-menu-outer {
    right: 0;
  }
}

.big-search-input-options {
  position: relative;

  .big-search-input.multiple-inputs.select-main-options {
    position: absolute;
    left: 15px;
    top: 0;

    .Select.clean-input {
      padding-right: 0;

      .Select-arrow-zone {
        width: 46px;
        padding: 0;
      }

      .Select-arrow {
        display: inline-block;
        background: @bookmd-main-color;
        width: 46px;
        height: 48px;
        border-radius: 0 6px 6px 0;

        &:after {
          color: #fff;
          content: '\f0d7';
          font-family: 'FontAwesome';
          font-size: 22px;
          vertical-align: -5px;
        }
      }
    }
  }

  &.requests,
  &.physician {
    padding-left: @requests-select-width + 10px;

    .big-search-input.multiple-inputs.select-main-options {
      width: @requests-select-width;
    }
  }
}
