//Tooltip icons
.tooltip-icon {
  cursor: pointer;
  color: @bookmd-text-dark-color;
  font-size: 20px;
  padding: 10px;
  position: relative;
  box-shadow: 0 0 0 0px @bookmd-providers-card-grey-title-color inset;
  border-radius: 50px;
  .transition (all 500ms ease);

  &:hover,
  &:active,
  &.active {
    //background: @bookmd-providers-card-grey-title-color;
    box-shadow: 0 0 0 50px @bookmd-providers-card-grey-title-color inset;
  }

  &.align-fix {
    padding: 10px 8px 10px 12px;
  }
}
.tooltip-wrap {
  display: inline-block;
}

.tooltip {
  z-index: 16000004;

  .tooltip-arrow {
    display: block;
  }

  .tooltip-inner {
    background: @bookmd-text-dark-color;
    color: #fff;
    font-size: 16px;
    font-weight: @semibold;
    font-family: 'proximaNova', Arial, Helvetica, sans-serif;
    padding: 6px 10px 4px 10px;
    border-radius: 4px !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    opacity: 1;
  }

  &.bottom {
    .tooltip-arrow {
      border-bottom-color: @bookmd-text-dark-color;
    }
  }
}
