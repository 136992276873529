.notification.notification-error {
  &:before {
    content: 'Error:';
    display: block;
    font-weight: bold;
    color: @bookmd-error-color;
  }
}
.notification-title {
  color: #000000 !important;
}
