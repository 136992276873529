@import '~@getvim/components-base-css/src/bookmd-import';

// Cards
.card {
  background: #fff;
  display: block;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  margin: 0 0 40px 0;
  max-width: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  }

  .card-title {
    display: block;
    padding: 0 20px;
    background: @bookmd-providers-card-grey-title-color;
    line-height: 48px;
    font-size: 16px;
    color: @bookmd-text-dark-color;
    font-weight: @semibold;
    position: relative;

    .btn.title-btn {
      border-radius: 0;
      color: #fff;
      line-height: 48px;
      padding: 0 20px;
      font-size: 16px;

      &.left-btn {
        margin: 0 30px 0 -20px;
      }

      i {
        vertical-align: -2px;
        padding: 0 5px 0 0;
      }
    }

    &.collapse-trigger {
      cursor: pointer;

      i.collapse-icon {
        position: absolute;
        font-size: 14px;
        right: 20px;
        overflow: hidden;
        height: 2px;
        width: 13px;
        background: @bookmd-text-dark-color;
        top: 22px;
        cursor: pointer;
      }
    }

    &.collapsed {
      i.collapse-icon {
        width: 14px;
        height: 14px;
        background: none;
        top: 16px;
      }
    }

    &.bg-main {
      background: @bookmd-main-color;
      color: #fff;

      .btn.title-btn {
        background: @bookmd-main-color-title-btn;
      }
    }
  }

  .card-body {
    padding: 40px 40px 1px 40px;
    max-width: 100%;

    .info-text {
      font-size: 16px;
      color: @bookmd-text-color;
      line-height: 26px;
      padding: 0 0 50px 0;

      .dark {
        color: @bookmd-text-dark-color;
      }
    }

    .provider-block-table {
      box-shadow: none;
    }

    .card-footer {
      padding: 0 0 40px 0;

      .add-new-footer-btn {
        padding: 20px;
        font-size: 16px;
        color: @bookmd-text-dark-color;

        i {
          font-size: 20px;
          vertical-align: -3px;
        }
      }

      .btn.margin-right {
        margin-right: 4px;
      }
    }

    &.padding-30 {
      padding: 30px 30px 0 30px;

      .card-footer {
        padding: 30px 0;

        &.bordered-footer {
          border-top: 1px solid @bookmd-border-color;
          padding: 15px 30px;
          margin-left: -30px;
          margin-right: -30px;
          overflow: hidden;
        }
      }
    }

    &.padding-box-30 {
      padding: 30px;
    }
  }

  &.info-card {
    .card-title {
      background: @bookmd-light-blue-color;
      color: #fff;

      i.icon-info-f {
        font-size: 25px;
        vertical-align: -6px;
        padding: 0 10px 0 0;
      }

      i.collapse-icon {
        background: #fff;
      }

      &.collapsed {
        i.collapse-icon {
          background: none;
        }
      }
    }
  }

  &.static-card {
    .card-body {
      padding: 40px;
    }

    .info-text {
      padding: 0 0 30px 0;
    }
  }

  &.details-card {
    .top-wrap {
      padding: 30px 20px 20px 20px;
      width: 100%;
      font-size: 14px;

      .details-icon {
        margin: 0 20px 0 0;
        display: inline-block;

        i {
          font-size: 40px;
          vertical-align: -9px;
          color: @bookmd-light-blue-color;
        }
      }

      .basic-info {
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          margin: 40px 40px 0 0;
          font-size: 18px;
          font-weight: bold;
          color: @bookmd-text-dark-color;

          .svg-icon {
            margin: 0 10px 0 0;
            vertical-align: -4px;
          }

          &:last-child {
            margin-right: 5px;
          }
        }
      }
    }

    .card-footer {
      padding: 10px 20px;
      box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);

      .btn + .btn {
        margin-left: 5px;
      }
    }

    .collapse-detail {
      padding: 16px 30px 15px 42px;
      font-size: 16px;
    }
  }

  &.id-card {
    .id-top {
      padding: 20px;
      position: relative;

      h3 {
        margin: 0;
      }

      .id-num {
        .svg-icon {
          vertical-align: -1px;
          margin: 0 5px 0 0;
        }
      }

      .npi-num {
        position: absolute;
        right: 35px;
        top: 45px;
      }

      .add-new-btn {
        right: 20px;
        .vertical-align-middle(absolute);
      }
    }

    .id-pic {
      width: 100px;
      height: 100px;
      margin: 0 20px 0 0;
      border-radius: 100px;
      display: inline-block;
      overflow: hidden;
      background: @bookmd-rp-light-grey-color;

      img {
        .img-cover;
      }
    }

    .id-icon {
      margin: 0 20px 0 0;
      border-radius: 100px;
      display: inline-block;

      i {
        font-size: 50px;
        color: @bookmd-light-blue-color;
      }
    }

    .id-name {
      display: inline-block;
      padding: 0 180px 0 90px;
      left: 0;
      max-width: 100%;
      .vertical-align-middle(absolute);

      h3 {
        max-width: 100%;
        .three-dots;
      }
    }

    .id-details-row {
      padding: 20px;
      font-size: 18px;

      .id-item {
        display: inline-block;
        padding: 0 30px 0 0;

        .svg-icon {
          margin: 0 5px 0 0;
          vertical-align: -2px;
        }
      }
    }

    .id-footer {
      padding: 20px;
      color: @bookmd-main-color;
      i.icon-plus {
        color: @bookmd-main-color;
      }
      &:hover {
        background-color: @bookmd-providers-card-grey-title-color;
        cursor: pointer;
      }
    }

    .select-btn {
      margin: 20px 20px 20px 0;
      min-width: 86px;
    }

    .provider-block-table {
      .btn.btn-round-plus {
        margin: 0 20px 0 0;
      }
    }

    .card-body .card-footer {
      padding: 0;
    }
  }

  .details-grid-row {
    padding: 30px 0 10px 0;
    line-height: 35px;

    span {
      font-size: 16px;
    }

    .text-dark {
      font-weight: bold;
    }
  }

  &.fixed-footer {
    background: none;
    box-shadow: none;
    overflow: visible;
    position: fixed;
    padding-bottom: 128px;
    width: calc(~'100% - ' 60px);
    height: calc(~'100% - ' 60px);

    .card-body {
      max-height: 100%;
      overflow: auto;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      background: #fff;
    }

    .card-footer {
      position: relative;
      margin-bottom: -68px;
      background: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  //Rounded card 
  &.rounded-card {
    border-radius: 20px;
    box-shadow: 0 15px 20px 0 rgba(8,58,107,0.1);
  }
}

//Cards stack
.cards-stack {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: visible;

  .card {
    position: absolute;
    width: 100%;
    height: 335px;

    left: 0;
    top: 0;
    .transition (all 300ms ease);

    &.item-1 {
      top: 0;
      left: -12px;
      background: #fff;
      z-index: 3;
    }

    &.item-2 {
      top: 6px;
      left: -6px;
      z-index: 2;
    }

    &.item-3 {
      top: 12px;
      left: 0;
      z-index: 1;

      &.active {
        left: -6px;
      }
    }

    .card-body {
      padding: 40px 20px 20px 20px;

      .card-footer {
        padding: 0 20px;
        position: absolute;
        width: 100%;
        bottom: 20px;
        left: 0;
      }
    }

    &.out {
      left: -200%;
      top: 20px;
      transform: rotate(-10deg);
      transform-origin: bottom left;
    }

    &.active {
      background: #fff !important;
      left: -12px;

      & + .card {
        background: @bookmd-lighter-blue-color;
        left: -6px;

        & + .card {
          background: @bookmd-lighter-blue-color-2;
        }
      }
    }
  }

  &.flow-cards {
    z-index: 1;
    margin: 0 0 0 -5px;

    .card {
      position: initial;
      animation: fade-in 400ms both;
      -webkit-animation: fade-in 400ms both;

      &.item-1 {
        margin: -5px 0 0 0;

        &:after,
        &:before {
          display: block;
          content: '';
          width: 100%;
          height: 335px;
          background: #fff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          position: absolute;
        }

        &:after {
          top: 5px;
          left: 5px;
          z-index: -1;
        }

        &:before {
          top: 10px;
          left: 10px;
          z-index: -2;
        }
      }

      &.item-2 {
        margin: 5px 0 0 5px;

        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 335px;
          background: #fff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: -1;
          margin: 0 0 0 5px;
        }
      }

      &.item-3 {
        margin: -5px 0 0 10px;
        position: absolute;
      }
    }
  }
}
