@import '~@getvim/components-base-css/src/bookmd-import';

.banner {
  &.top {
    margin: 0 -10px 10px -10px;
    padding: 30px 30px 20px 30px;
    color: #fff;
    text-align: center;
    position: relative;

    .close-banner-btn {
      position: absolute;
      right: 18px;
      top: 10px;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        color: #fff;
      }
    }

    .banner-right-btn {
      margin: 20px 0 0 0;

      @media (min-width: @screen-sm) {
        margin: 7px 0 0 0;
        float: right;
      }
    }

    .banner-icon,
    .svg-icon {
      font-size: 18px;
      display: inline-block;
      margin: 0 5px 0 0;

      .fill-color-brand-main,
      .fill-color-brand-secondary {
        fill: #fff;
      }

      @media (min-width: @screen-sm) {
        font-size: 36px;
        margin: 2px 0 0 -58px;
        position: absolute;
      }
    }

    .svg-icon {
      width: 36px;
      height: auto;
      margin-top: 7px;

      @media (max-width: @screen-sm-no-ipad) {
        width: 18px;
        margin: 0 10px -2px 0;
      }
    }

    @media (min-width: @screen-sm) {
      padding: 20px 50px 20px 85px;
      text-align: left;
    }
  }
}
