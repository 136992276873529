@import '~@getvim/components-base-css/src/bookmd-import';

// BUTTONS
.btn {
  &:focus,
  &:active,
  &:focus:active {
    outline: none;
  }

  &.btn-big {
    background: @bookmd-green-color;
    border-radius: 4px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-family: 'ProximaNova';
    font-weight: @medium;
    text-align: center;
    color: #fff;
    text-transform: none;
    padding: 0 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 0;
    max-width: 400px;
    min-width: 200px;

    &.inline {
      display: inline-block;
    }

    &.btn-break-line {
      min-height: 48px;
      white-space: normal;
      line-height: normal;
      height: auto;
      padding: 10px;
    }

    &.btn-big-white {
      background: #fff;
      color: inherit;
      font-weight: inherit;

      &:hover,
      &:active,
      &:focus {
        background: #fff !important;
      }
    }

    &.btn-sm-block {
      @media (min-width: @screen-sm) {
        max-width: 100%;
        min-width: 0;
        width: 100%;
      }
    }

    &:focus {
      background: @bookmd-providers-green;
    }

    &:active,
    &.active {
      background: @bookmd-green-color-dark;
      box-shadow: none;
    }

    @media (max-width: @screen-sm) {
      /*////*/
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      /*////*/
    }
  }

  &.btn-min-100 {
    min-width: 100px;
  }

  &.btn-min-300 {
    min-width: 300px;
  }

  &.btn-only-icon {
    min-width: 48px !important;
  }

  &.inline-right {
    position: absolute;
    top: 0;
    right: -4px;
    z-index: 2;
    height: 48px;
    border-radius: 0px 6px 6px 0px;
    font-size: 24px;
    font-weight: @semibold;
  }

  &.btn-huge {
    width: auto;
    font-size: 36px;
    font-weight: 600;
    height: 72px;
    box-shadow: none !important;
    margin: 50px 0 0 0;
    padding: 0 40px;

    @media (max-width: @screen-md) {
      height: 64px;
      padding: 0 20px;
      font-size: 24px;
    }

    @media (max-width: @screen-sm) {
      height: 56px;
      font-size: 16px;
      padding: 0 20px;
      max-width: 100%;
    }
  }

  &.btn-transparent {
    outline: 0 none;
    background: none;
    box-shadow: none !important;

    &.grey-text {
      color: @bookmd-text-color;
    }

    &:active,
    &:hover,
    &:focus,
    &.active {
      background: none;
    }
  }

  &.btn-white {
    background: #fff;
    height: 37px;
    color: @bookmd-main-color;
    font-size: 14px;
    font-family: 'ProximaNova';
    font-weight: 600;
    box-shadow: none !important;
    text-transform: none;
  }

  &.btn-blue {
    background: @bookmd-light-blue-color !important;
    color: #fff;

    &:focus {
      background: @bookmd-light-blue-color-focus;
    }

    &:active,
    &.active {
      background: @bookmd-light-blue-color-dark;
    }
  }

  &.btn-green {
    background: @bookmd-green-color;
    color: #fff;
  }

  &.btn-red {
    background: @bookmd-error-color;
    color: #fff;
  }

  &.btn-grey {
    background: @bookmd-text-color;
    color: #fff;

    &:focus,
    &:active {
      background: @bookmd-text-dark-color !important;
    }
  }

  &.btn-light-grey {
    background: @bookmd-providers-card-grey-title-color;
    color: @case-past-emergency !important;

    &:active,
    &:focus {
      background: @bookmd-providers-light-grey !important;
      color: @case-past-emergency !important;
    }

    &:hover {
      background: @bookmd-rp-hover-grey-color;
    }
  }

  &.btn-outline {
    background: #fff;
    border: 1px solid @bookmd-providers-light-grey;
    color: @bookmd-text-dark-color;

    &:focus {
      border: 1px solid @bookmd-text-dark-color;
      background: #fff;
      box-shadow: none;
      outline: none;
    }

    &:active,
    &.active {
      border: 1px solid @bookmd-text-dark-color;
      background: @bookmd-providers-card-grey-title-color;
      outline: none;
      box-shadow: none;
    }

    &:hover {
      background: @bookmd-rp-light-grey-color;
    }

    &.selected {
      border-color: @bookmd-light-blue-color;
      color: @bookmd-light-blue-color !important;

      &:active,
      &.active {
        background: @bookmd-providers-light-blue;
      }
    }

    &.btn-trans {
      background: none;
    }
  }

  &.btn-help {
    position: absolute;
    top: 4px;
    right: 0;
    color: #fff;
    text-transform: none;
    font-size: 16px;
    font-weight: @semibold;

    &.blue {
      color: @bookmd-main-color;
    }

    &.grey {
      color: @bookmd-text-color;
    }

    &.icon {
      top: 0;

      i {
        color: @bookmd-text-color;
        font-size: 25px;
      }
    }

    @media (min-width: @screen-lg) {
      //top: 44px;
    }
  }

  &.btn-back {
    position: absolute;
    top: 4px;
    left: 0;
    color: #fff;
    text-transform: none;
    font-size: 14px;

    i {
      font-size: 20px;
      vertical-align: -2px;
      padding: 0 3px 0 0;
    }

    &.blue {
      color: @bookmd-main-color;
    }

    &.icon {
      top: 5px;

      i {
        color: @bookmd-text-color;
        font-size: 20px;
      }
    }

    @media (min-width: @screen-lg) {
      top: 44px;
    }
  }

  &.btn-bottom {
    margin-bottom: 50px;
  }

  &.btn-small {
    padding: 5px 15px;
    color: #fff;
    font-size: 14px;
    height: 36px;
    font-weight: @semibold;

    &.btn-outline {
      color: @bookmd-text-dark-color;
    }

    .two-lines-btn & {
      height: auto;
      min-height: 56px;
      white-space: normal;
    }

    &.btn-sm-block {
      @media (max-width: @screen-sm) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &.btn-max-300 {
    max-width: 300px;
    min-width: 200px;
  }

  &.btn-trans-border {
    height: 44px;
    border: 1px solid #fff;
    background: none;
    width: 100%;
    font-size: 16px;
    color: #fff;
    line-height: 44px;
    font-weight: @medium;
    border-radius: 2px;
    padding: 0;
  }

  &.btn-white-bordered {
    background: #fff;
    border: 2px solid @bookmd-text-color;
    padding: 7px 14px;
    border-radius: 3px;
    font-size: 14px;

    &.active,
    &:active,
    &:focus {
      box-shadow: none !important;
      color: @bookmd-light-blue-color;
      background: @bookmd-lighter-blue-color;
      border: 2px solid @bookmd-light-blue-color;
    }

    &.disabled,
    :disabled,
    &[disabled] {
      border: 2px solid @bookmd-text-color !important;
      color: #7c7c7c !important;
      background: #fff;
    }

    &.big {
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      font-family: 'ProximaNova';
      font-weight: 600;
      text-align: center;
      width: 100%;
      text-transform: none;
      padding: 0;
    }

    &.light {
      font-size: 16px;
      line-height: 16px;
      border-width: 1px;
      border-color: @bookmd-border-color;
      padding: 14px 25px;
      color: @bookmd-light-blue-color;
    }
  }

  &.btn-checkbox {
    position: relative;
    width: 100%;
    max-width: 100% !important;

    &.selected {
      &:after {
        opacity: 1;
      }
    }

    &:focus,
    &:active {
      outline: 0;
      box-shadow: 0;
      background: none;
    }

    @media (max-width: @screen-xs) {
      font-size: 15px;
    }
  }

  &.btn-round-plus {
    min-width: 36px;
    min-height: 36px;
    height: 36px;
    width: auto;
    line-height: 36px;
    border-radius: 36px;
    background: @bookmd-green-color;
    color: #fff;
    font-size: 16px;
    padding: 0 15px 0 10px;
    .transition(ease 200ms all);

    i {
      vertical-align: -2px;
      font-size: 17px;
    }

    .btn-text {
      margin: 0 0 0 10px;
      display: inline;
    }

    &.btn-big {
      min-width: 48px;
      min-height: 48px;
      height: 48px;
      width: 48px;
      line-height: 48px;
      border-radius: 48px;
      padding: 0;
      background: @bookmd-light-blue-color;

      i {
        font-size: 24px;
        vertical-align: -6px;
      }

      &:hover,
      &:active,
      &:focus,
      &.active {
        background: darken(@bookmd-light-blue-color, 5%);
      }
    }

    &:hover {
      width: auto;
      background: @bookmd-green-color-dark;
    }

    /** Fix spinner position */
    &.ladda-button {
      overflow: visible;
    }
  }

  &.ladda-button[data-style='zoom-in'][data-loading] {
    .ladda-label {
      opacity: 0;
      -webkit-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }

  &.btn-inline-block {
    display: inline-block !important;
  }

  &.btn-white-border {
    border: 1px solid #fff !important;
  }

  &.btn-inline-link {
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    background: none !important;
    font-size: inherit;
    padding: 0;

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }

  &.btn-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &.btn-pill {
    height: 48px;
    line-height: 48px;
    padding: 0 15px;
    display: inline-block;
    border: 1px solid @bookmd-providers-light-grey;
    font-size: 15px;
    background: #fff;
    color: @bookmd-text-color;
    border-radius: 50px;

    &.btn-small {
      height: 36px;
      line-height: 36px;
    }

    &:active,
    &.selected {
      border: 1px solid @bookmd-text-dark-color;
      color: @bookmd-text-dark-color;
    }
  }

  &.btn-round-icon {
    border-radius: 50px;
    width: 36px;
    height: 36px;
    line-height: 42px;
    font-size: 20px;
    padding: 0;
    box-shadow: 0 0 0 0 @bookmd-providers-card-grey-title-color inset;
    .transition(all 400ms ease);

    &:hover,
    &:active,
    &.active {
      box-shadow: 0 0 0 100px @bookmd-providers-card-grey-title-color inset;
    }
  }

  &.btn-outline-pill {
    border-radius: 26px;
    height: 26px;
    line-height: 26px;
    padding: 0 30px;
    font-size: 14px;
    font-weight: normal;
    background: #fff;
    border-style: solid;
    border-width: 1px;
    .btn-outline-pill-mixin();
    .transition(box-shadow 200ms ease);
  }

  &.two-lines-btn {
    height: auto !important;
    line-height: normal !important;
    padding: 10px 20px !important;
    white-space: normal !important;
    max-width: 100% !important;
  }

  &.disabled,
  :disabled,
  &[disabled] {
    opacity: 0.4;
  }
}

//Buttons groups
.btn-group-centered {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  .horizontal-align-middle();

  .btn-wrap {
    @media (max-width: @screen-sm-no-ipad) {
      &:only-of-type {
        width: 100%;
        display: inline-block;
        text-align: center;
      }
    }
  }

  & > .btn,
  & > .btn-wrap > .btn {
    display: inline-block !important;
    margin: 0 2px;

    @media (max-width: @screen-sm-no-ipad) {
      min-width: 48%;
      max-width: 48%;
      margin: 0 1%;
    }
  }

  @media (max-width: @screen-sm-no-ipad) {
    width: 100%;
  }
}
