@import '~@getvim/components-base-css/src/bookmd-import';

body {

  * {
    &:focus {
      outline: none
    }
  }

  &.accessibility-tab-focus {
    * {
      &:focus {
        outline: @bookmd-text-color 2px dotted !important;
        outline-offset: 3px;
      }

      &.white-focus {
        &:focus {
          outline: #fff 2px dotted !important;
        }
      }
    }
  }
}