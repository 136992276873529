body {
  font-family: 'proximaNova', Helvetica, Arial, sans-serif;
  color: #6e6e6e;
  font-size: 15px;
}
.text-strong-dark {
  color: #5c5c5c !important;
  font-weight: bold;
}
.text-strong-danger {
  color: #b12704 !important;
  font-weight: bold;
}
.text-strong-orange {
  color: #f48823 !important;
  font-weight: bold;
}
.text-strong-success {
  color: #278600 !important;
  font-weight: bold;
}
.text-strong-faded {
  color: #d8d8d8 !important;
  font-weight: bold;
}
.text-light-blue {
  color: #09ace4 !important;
}
.text-light-blue-dark {
  color: #007bbc !important;
}
.text-strong-light-blue {
  color: #09ace4 !important;
  font-weight: bold;
}
.text-strong-main {
  color: #083a6b !important;
  font-weight: bold;
}
.text-orange {
  color: #f48823;
}
.text-success {
  color: #278600 !important;
}
.text-faded {
  color: #d8d8d8;
}
.text-danger {
  color: #b12704;
}
.text-dark {
  color: #5c5c5c;
}
.text-extra-dark {
  color: #1f1f1f !important;
}
.text-ultra-dark {
  color: #32333C !important;
}
.text-main {
  color: #083a6b;
}
.text-light-blue {
  color: #09ace4 !important;
}
.text-white {
  color: #fff !important;
}
.text-pink {
  color: #f94e87;
}
.text-pink-dark {
  color: #d72b5e;
}
.text-theme-secondary {
  color: var(--secondary-color);
}
.text-theme-main {
  color: var(--main-color);
}
.text-semibold {
  font-weight: 600 !important;
}
.text-no-line-breaks {
  white-space: nowrap;
}
.text-medium {
  font-weight: 500 !important;
}
.text-light {
  font-weight: 100 !important;
}
.text-black {
  font-weight: 900 !important;
}
.text-underline {
  text-decoration: underline;
}
.text-underline-dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
}
.text-normal {
  font-weight: normal !important;
}
.text-strong {
  font-weight: bold !important;
}
.text-24 {
  font-size: 24px;
}
.text-22 {
  font-size: 22px;
}
.text-18 {
  font-size: 18px;
}
.text-16 {
  font-size: 16px;
}
.text-14 {
  font-size: 14px;
}
.text-15 {
  font-size: 15px;
}
.text-12 {
  font-size: 12px;
}
.text-13 {
  font-size: 13px;
}
.title-24 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.title-30 {
  font-size: 30px;
}
.title-36 {
  font-size: 36px;
}
.title-48 {
  font-size: 48px;
}
.title-24.md-margin,
.title-36.md-margin,
.title-48.md-margin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.title-24.margin-30,
.title-36.margin-30,
.title-48.margin-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.title-24.margin-10,
.title-36.margin-10,
.title-48.margin-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-13 {
  margin: 13px;
}
.text-nowrap {
  white-space: nowrap;
}
.three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-line-through {
  text-decoration: line-through;
}
hr {
  border-top: 1px solid #e2e2e2;
}
a {
  color: inherit;
}
a:active,
a:hover,
a:focus,
a:visited {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  line-height: normal;
}
h2 {
  font-size: inherit;
  line-height: inherit;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.ttf') format('truetype');
  font-weight: 100;
  /*// light //*/
}
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.ttf') format('truetype');
  font-weight: 500;
  /*// medium //*/
}
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.ttf') format('truetype');
  font-weight: 600;
  /*// semibold //*/
}
@font-face {
  font-family: 'lorabold';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lorabold_italic';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'loraitalic';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'loraregular';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.eot?#iefix') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'), url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.ttf') format('truetype');
  /* Safari, Android, iOS */
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 200;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLight_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLight_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 200;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLightItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLightItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 300;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Light_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Light_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 300;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-LightItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-LightItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 400;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Book_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Book_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 400;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BookItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BookItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 500;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Medium_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Medium_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 500;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-MediumItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-MediumItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 700;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Bold_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Bold_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 700;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BoldItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BoldItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 800;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Black_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Black_Web.woff') format('woff');
}
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 800;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BlackItalic_Web.woff2') format('woff2'), url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BlackItalic_Web.woff') format('woff');
}
@font-face {
  font-family: 'Brownhill';
  src: url('~@getvim/components-base-css/src/fonts/brownhill/Brownhill.woff') format('woff'), url('~@getvim/components-base-css/src/fonts/brownhill/Brownhill.ttf') format('woff2');
}
/* source-sans-pro-200 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-200italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-300 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-300italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-regular - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-600 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-600italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-700 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-700italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-900 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
/* source-sans-pro-900italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.eot');
  /* IE9 Compat Modes */
  src: local(''), url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.woff') format('woff'), /* Modern Browsers */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.svg#SourceSansPro') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: 'SF Pro Text Regular';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Regular.woff');
  src: local(''), url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Regular.woff2');
}
@font-face {
  font-family: 'SF Pro Text Medium';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Medium.woff');
  src: local(''), url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Medium.woff2');
}
@font-face {
  font-family: 'SF Pro Text Bold';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Bold.woff');
  src: local(''), url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Bold.woff2');
}
.margin-bottom-clean-form {
  margin-bottom: 40px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.no-margin {
  margin: 0 !important;
}
.no-v-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.margin-top {
  margin-top: 15px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-right-20 {
  margin-right: 20px;
}
.full-width-flow-step {
  margin-left: -30px;
  margin-right: -30px;
}
.no-padding {
  padding: 0 !important;
}
.no-v-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media (min-width: 992px) {
  .md-no-padding {
    padding: 0 !important;
  }
}
.padding-box-30 {
  padding: 30px;
}
.padding-box-20 {
  padding: 20px;
}
.padding-box-10 {
  padding: 10px;
}
.padding-v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.padding-v-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.padding-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-h-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.padding-h-30-important {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.padding-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.blue-link {
  color: #09ace4 !important;
  cursor: pointer;
}
.blue-link:hover {
  color: #0193d1 !important;
  text-decoration: none;
}
.default-cursor-override {
  cursor: default;
}
.underline-link {
  color: #6e6e6e;
  text-decoration: underline;
}
.break-long-words {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.show {
  opacity: 1 !important;
}
.hide {
  opacity: 0;
}
.equal-cols {
  display: table;
}
.equal-cols .cell-col {
  display: table-cell;
  float: none;
}
.equal-flex-cards {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0 30px;
}
.equal-flex-cards > div {
  width: calc(33.33% - 30px);
  margin: 0 15px 30px 15px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .equal-flex-cards > div {
    /* IE10+ CSS styles */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  }
}
.equal-flex-cards > div > .card {
  box-shadow: none;
}
@media (max-width: 1200px) {
  .equal-flex-cards.md-blocks {
    flex-direction: column;
    align-items: center;
    margin: 0 0 10px 0;
  }
  .equal-flex-cards.md-blocks > div {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .equal-flex-cards.md-blocks > div {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.equal-flex-cards.auto-width > div {
  width: auto;
  flex: 1;
}
.equal-flex-cards.horizontal-list {
  flex-direction: column;
}
.equal-flex-cards.horizontal-list > div {
  width: 100% !important;
  margin: 0 0 10px 0;
}
.equal-flex-cards.horizontal-list > div .card-body {
  flex-direction: row;
}
@media (max-width: 768px) {
  .xs-block {
    display: block !important;
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
@media (max-width: 1040px) {
  .hidden-1040 {
    display: inherit;
  }
}
@media (min-width: 1040px) {
  .hidden-1040 {
    display: none;
  }
}
@media (max-width: 568px) {
  .hidden-xxs {
    display: none;
  }
}
@media (max-width: 1040px) {
  .visible-1040 {
    display: none;
  }
}
@media (min-width: 1040px) {
  .visible-1040 {
    display: inherit;
  }
}
.inline-block {
  display: inline-block !important;
}
.block {
  display: block !important;
}
@media (min-width: 768px) {
  .sm-pull-left {
    float: left;
  }
}
@media (min-width: 768px) {
  .sm-pull-right {
    float: right;
  }
}
.row.small-gutter {
  margin-right: -5px;
  margin-left: -5px;
}
.row.small-gutter > div {
  padding-left: 5px;
  padding-right: 5px;
}
.row.tiny-gutter {
  margin-right: -2px;
  margin-left: -2px;
}
.row.tiny-gutter > div {
  padding-left: 2px;
  padding-right: 2px;
}
.col-7 {
  width: 14.28%;
  float: left;
}
.col-5 {
  width: 20%;
  float: left;
}
.clear-floats {
  clear: both;
}
html,
body {
  height: 100%;
}
.container.container-1040 {
  max-width: 1040px;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .container.container-1040 {
    width: 100%;
  }
}
.content-grey-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 56px 0 0 0;
  background: #f2f2f2;
  overflow-x: hidden;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
}
@media (min-width: 768px) {
  .content-grey-bg {
    padding: 64px 0 0 0;
  }
}
.i-va-fix-2 {
  vertical-align: -2px;
}
.i-va-fix-3 {
  vertical-align: -3px;
}
.bg-main-color {
  background: #083a6b;
  color: #fff;
}
.bg-main-color h2,
.bg-main-color h3,
.bg-main-color h4 {
  color: #fff;
}
.bg-grey-color {
  background-color: #f2f2f2 !important;
}
.bg-providers-grey-color {
  background-color: #d8d8d8 !important;
}
.bg-light-blue-color-dark {
  background: #007bbc;
}
.bg-light-blue-color {
  background: #09ace4;
}
.bg-orange-color {
  background: #f48823 !important;
}
.bg-success,
.bg-success-color {
  background: #278600;
}
.bg-pink,
.bg-pink-color {
  background: #f94e87;
}
.bg-providers-success-color {
  background: #278600;
}
.bg-providers-pink-color {
  background: #f63277;
}
.bg-providers-orange-color {
  background: #f89e06;
}
.bg-providers-error-color {
  background: #b12704 !important;
}
.bg-mild-grey-color {
  background: #6e6e6e !important;
}
.bg-transperent,
.bg-transparent {
  background-color: transparent !important;
}
.no-border {
  border-width: 0px;
}
.or-hr .or-text {
  display: inline-block;
  padding: 0 10px;
  margin: 10px 0;
  background: #fff;
  z-index: 2;
  position: relative;
}
.or-hr:before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -1px 0 0 0;
  background: #e2e2e2;
  z-index: 1;
}
.half-opacity {
  opacity: 0.5;
}
.svg-32 > .svg-icon {
  height: 32px;
  width: auto;
}
.svg-16 > .svg-icon {
  height: 16px;
  width: auto;
}
.svg-72 > .svg-icon {
  height: 72px;
  width: auto;
}
.right-border {
  border-right: 1px solid #e2e2e2;
}
@media (max-width: 767px) {
  .right-border {
    border-right: none;
    border-bottom: 1px solid #e2e2e2;
  }
}
.left-border {
  border-left: 1px solid #e2e2e2;
}
@media (max-width: 767px) {
  .left-border {
    border-left: none;
    border-top: 1px solid #e2e2e2;
  }
}
.off-screen-text {
  position: absolute;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  width: 1px;
  line-height: 1px;
  margin: 0;
}
.gpu-render {
  transform: translateZ(0);
}
.no-shadow {
  box-shadow: none !important;
}
.card {
  background: #fff;
  display: block;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  margin: 0 0 40px 0;
  max-width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card {
    /* IE10+ CSS styles */
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  }
}
.card .card-title {
  display: block;
  padding: 0 20px;
  background: #eaeaea;
  line-height: 48px;
  font-size: 16px;
  color: #5c5c5c;
  font-weight: 600;
  position: relative;
}
.card .card-title .btn.title-btn {
  border-radius: 0;
  color: #fff;
  line-height: 48px;
  padding: 0 20px;
  font-size: 16px;
}
.card .card-title .btn.title-btn.left-btn {
  margin: 0 30px 0 -20px;
}
.card .card-title .btn.title-btn i {
  vertical-align: -2px;
  padding: 0 5px 0 0;
}
.card .card-title.collapse-trigger {
  cursor: pointer;
}
.card .card-title.collapse-trigger i.collapse-icon {
  position: absolute;
  font-size: 14px;
  right: 20px;
  overflow: hidden;
  height: 2px;
  width: 13px;
  background: #5c5c5c;
  top: 22px;
  cursor: pointer;
}
.card .card-title.collapsed i.collapse-icon {
  width: 14px;
  height: 14px;
  background: none;
  top: 16px;
}
.card .card-title.bg-main {
  background: #083a6b;
  color: #fff;
}
.card .card-title.bg-main .btn.title-btn {
  background: #1862ab;
}
.card .card-body {
  padding: 40px 40px 1px 40px;
  max-width: 100%;
}
.card .card-body .info-text {
  font-size: 16px;
  color: #6e6e6e;
  line-height: 26px;
  padding: 0 0 50px 0;
}
.card .card-body .info-text .dark {
  color: #5c5c5c;
}
.card .card-body .provider-block-table {
  box-shadow: none;
}
.card .card-body .card-footer {
  padding: 0 0 40px 0;
}
.card .card-body .card-footer .add-new-footer-btn {
  padding: 20px;
  font-size: 16px;
  color: #5c5c5c;
}
.card .card-body .card-footer .add-new-footer-btn i {
  font-size: 20px;
  vertical-align: -3px;
}
.card .card-body .card-footer .btn.margin-right {
  margin-right: 4px;
}
.card .card-body.padding-30 {
  padding: 30px 30px 0 30px;
}
.card .card-body.padding-30 .card-footer {
  padding: 30px 0;
}
.card .card-body.padding-30 .card-footer.bordered-footer {
  border-top: 1px solid #e2e2e2;
  padding: 15px 30px;
  margin-left: -30px;
  margin-right: -30px;
  overflow: hidden;
}
.card .card-body.padding-box-30 {
  padding: 30px;
}
.card.info-card .card-title {
  background: #09ace4;
  color: #fff;
}
.card.info-card .card-title i.icon-info-f {
  font-size: 25px;
  vertical-align: -6px;
  padding: 0 10px 0 0;
}
.card.info-card .card-title i.collapse-icon {
  background: #fff;
}
.card.info-card .card-title.collapsed i.collapse-icon {
  background: none;
}
.card.static-card .card-body {
  padding: 40px;
}
.card.static-card .info-text {
  padding: 0 0 30px 0;
}
.card.details-card .top-wrap {
  padding: 30px 20px 20px 20px;
  width: 100%;
  font-size: 14px;
}
.card.details-card .top-wrap .details-icon {
  margin: 0 20px 0 0;
  display: inline-block;
}
.card.details-card .top-wrap .details-icon i {
  font-size: 40px;
  vertical-align: -9px;
  color: #09ace4;
}
.card.details-card .top-wrap .basic-info {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.card.details-card .top-wrap .basic-info li {
  display: inline-block;
  margin: 40px 40px 0 0;
  font-size: 18px;
  font-weight: bold;
  color: #5c5c5c;
}
.card.details-card .top-wrap .basic-info li .svg-icon {
  margin: 0 10px 0 0;
  vertical-align: -4px;
}
.card.details-card .top-wrap .basic-info li:last-child {
  margin-right: 5px;
}
.card.details-card .card-footer {
  padding: 10px 20px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);
}
.card.details-card .card-footer .btn + .btn {
  margin-left: 5px;
}
.card.details-card .collapse-detail {
  padding: 16px 30px 15px 42px;
  font-size: 16px;
}
.card.id-card .id-top {
  padding: 20px;
  position: relative;
}
.card.id-card .id-top h3 {
  margin: 0;
}
.card.id-card .id-top .id-num .svg-icon {
  vertical-align: -1px;
  margin: 0 5px 0 0;
}
.card.id-card .id-top .npi-num {
  position: absolute;
  right: 35px;
  top: 45px;
}
.card.id-card .id-top .add-new-btn {
  right: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.card.id-card .id-pic {
  width: 100px;
  height: 100px;
  margin: 0 20px 0 0;
  border-radius: 100px;
  display: inline-block;
  overflow: hidden;
  background: #f5f5f5;
}
.card.id-card .id-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card.id-card .id-icon {
  margin: 0 20px 0 0;
  border-radius: 100px;
  display: inline-block;
}
.card.id-card .id-icon i {
  font-size: 50px;
  color: #09ace4;
}
.card.id-card .id-name {
  display: inline-block;
  padding: 0 180px 0 90px;
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.card.id-card .id-name h3 {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card.id-card .id-details-row {
  padding: 20px;
  font-size: 18px;
}
.card.id-card .id-details-row .id-item {
  display: inline-block;
  padding: 0 30px 0 0;
}
.card.id-card .id-details-row .id-item .svg-icon {
  margin: 0 5px 0 0;
  vertical-align: -2px;
}
.card.id-card .id-footer {
  padding: 20px;
  color: #083a6b;
}
.card.id-card .id-footer i.icon-plus {
  color: #083a6b;
}
.card.id-card .id-footer:hover {
  background-color: #eaeaea;
  cursor: pointer;
}
.card.id-card .select-btn {
  margin: 20px 20px 20px 0;
  min-width: 86px;
}
.card.id-card .provider-block-table .btn.btn-round-plus {
  margin: 0 20px 0 0;
}
.card.id-card .card-body .card-footer {
  padding: 0;
}
.card .details-grid-row {
  padding: 30px 0 10px 0;
  line-height: 35px;
}
.card .details-grid-row span {
  font-size: 16px;
}
.card .details-grid-row .text-dark {
  font-weight: bold;
}
.card.fixed-footer {
  background: none;
  box-shadow: none;
  overflow: visible;
  position: fixed;
  padding-bottom: 128px;
  width: calc(100% -  60px);
  height: calc(100% -  60px);
}
.card.fixed-footer .card-body {
  max-height: 100%;
  overflow: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
}
.card.fixed-footer .card-footer {
  position: relative;
  margin-bottom: -68px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 2px rgba(0, 0, 0, 0.2);
}
.card.rounded-card {
  border-radius: 20px;
  box-shadow: 0 15px 20px 0 rgba(8, 58, 107, 0.1);
}
.cards-stack {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: visible;
}
.cards-stack .card {
  position: absolute;
  width: 100%;
  height: 335px;
  left: 0;
  top: 0;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.cards-stack .card.item-1 {
  top: 0;
  left: -12px;
  background: #fff;
  z-index: 3;
}
.cards-stack .card.item-2 {
  top: 6px;
  left: -6px;
  z-index: 2;
}
.cards-stack .card.item-3 {
  top: 12px;
  left: 0;
  z-index: 1;
}
.cards-stack .card.item-3.active {
  left: -6px;
}
.cards-stack .card .card-body {
  padding: 40px 20px 20px 20px;
}
.cards-stack .card .card-body .card-footer {
  padding: 0 20px;
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
}
.cards-stack .card.out {
  left: -200%;
  top: 20px;
  transform: rotate(-10deg);
  transform-origin: bottom left;
}
.cards-stack .card.active {
  background: #fff !important;
  left: -12px;
}
.cards-stack .card.active + .card {
  background: #d6ebf9;
  left: -6px;
}
.cards-stack .card.active + .card + .card {
  background: #b4d3e8;
}
.cards-stack.flow-cards {
  z-index: 1;
  margin: 0 0 0 -5px;
}
.cards-stack.flow-cards .card {
  position: initial;
  animation: fade-in 400ms both;
  -webkit-animation: fade-in 400ms both;
}
.cards-stack.flow-cards .card.item-1 {
  margin: -5px 0 0 0;
}
.cards-stack.flow-cards .card.item-1:after,
.cards-stack.flow-cards .card.item-1:before {
  display: block;
  content: '';
  width: 100%;
  height: 335px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
}
.cards-stack.flow-cards .card.item-1:after {
  top: 5px;
  left: 5px;
  z-index: -1;
}
.cards-stack.flow-cards .card.item-1:before {
  top: 10px;
  left: 10px;
  z-index: -2;
}
.cards-stack.flow-cards .card.item-2 {
  margin: 5px 0 0 5px;
}
.cards-stack.flow-cards .card.item-2:after {
  display: block;
  content: '';
  width: 100%;
  height: 335px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -1;
  margin: 0 0 0 5px;
}
.cards-stack.flow-cards .card.item-3 {
  margin: -5px 0 0 10px;
  position: absolute;
}
.btn:focus,
.btn:active,
.btn:focus:active {
  outline: none;
}
.btn.btn-big {
  background: #278600;
  border-radius: 4px;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  font-family: 'ProximaNova';
  font-weight: 500;
  text-align: center;
  color: #fff;
  text-transform: none;
  padding: 0 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-width: 0;
  max-width: 400px;
  min-width: 200px;
}
.btn.btn-big.inline {
  display: inline-block;
}
.btn.btn-big.btn-break-line {
  min-height: 48px;
  white-space: normal;
  line-height: normal;
  height: auto;
  padding: 10px;
}
.btn.btn-big.btn-big-white {
  background: #fff;
  color: inherit;
  font-weight: inherit;
}
.btn.btn-big.btn-big-white:hover,
.btn.btn-big.btn-big-white:active,
.btn.btn-big.btn-big-white:focus {
  background: #fff !important;
}
@media (min-width: 768px) {
  .btn.btn-big.btn-sm-block {
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }
}
.btn.btn-big:focus {
  background: #278600;
}
.btn.btn-big:active,
.btn.btn-big.active {
  background: #097200;
  box-shadow: none;
}
@media (max-width: 768px) {
  .btn.btn-big {
    /*////*/
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    /*////*/
  }
}
.btn.btn-min-100 {
  min-width: 100px;
}
.btn.btn-min-300 {
  min-width: 300px;
}
.btn.btn-only-icon {
  min-width: 48px !important;
}
.btn.inline-right {
  position: absolute;
  top: 0;
  right: -4px;
  z-index: 2;
  height: 48px;
  border-radius: 0px 6px 6px 0px;
  font-size: 24px;
  font-weight: 600;
}
.btn.btn-huge {
  width: auto;
  font-size: 36px;
  font-weight: 600;
  height: 72px;
  box-shadow: none !important;
  margin: 50px 0 0 0;
  padding: 0 40px;
}
@media (max-width: 992px) {
  .btn.btn-huge {
    height: 64px;
    padding: 0 20px;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .btn.btn-huge {
    height: 56px;
    font-size: 16px;
    padding: 0 20px;
    max-width: 100%;
  }
}
.btn.btn-transparent {
  outline: 0 none;
  background: none;
  box-shadow: none !important;
}
.btn.btn-transparent.grey-text {
  color: #6e6e6e;
}
.btn.btn-transparent:active,
.btn.btn-transparent:hover,
.btn.btn-transparent:focus,
.btn.btn-transparent.active {
  background: none;
}
.btn.btn-white {
  background: #fff;
  height: 37px;
  color: #083a6b;
  font-size: 14px;
  font-family: 'ProximaNova';
  font-weight: 600;
  box-shadow: none !important;
  text-transform: none;
}
.btn.btn-blue {
  background: #09ace4 !important;
  color: #fff;
}
.btn.btn-blue:focus {
  background: #0190d1;
}
.btn.btn-blue:active,
.btn.btn-blue.active {
  background: #007bbc;
}
.btn.btn-green {
  background: #278600;
  color: #fff;
}
.btn.btn-red {
  background: #b12704;
  color: #fff;
}
.btn.btn-grey {
  background: #6e6e6e;
  color: #fff;
}
.btn.btn-grey:focus,
.btn.btn-grey:active {
  background: #5c5c5c !important;
}
.btn.btn-light-grey {
  background: #eaeaea;
  color: #545454 !important;
}
.btn.btn-light-grey:active,
.btn.btn-light-grey:focus {
  background: #d8d8d8 !important;
  color: #545454 !important;
}
.btn.btn-light-grey:hover {
  background: #e4e4e4;
}
.btn.btn-outline {
  background: #fff;
  border: 1px solid #d8d8d8;
  color: #5c5c5c;
}
.btn.btn-outline:focus {
  border: 1px solid #5c5c5c;
  background: #fff;
  box-shadow: none;
  outline: none;
}
.btn.btn-outline:active,
.btn.btn-outline.active {
  border: 1px solid #5c5c5c;
  background: #eaeaea;
  outline: none;
  box-shadow: none;
}
.btn.btn-outline:hover {
  background: #f5f5f5;
}
.btn.btn-outline.selected {
  border-color: #09ace4;
  color: #09ace4 !important;
}
.btn.btn-outline.selected:active,
.btn.btn-outline.selected.active {
  background: #ddeaf6;
}
.btn.btn-outline.btn-trans {
  background: none;
}
.btn.btn-help {
  position: absolute;
  top: 4px;
  right: 0;
  color: #fff;
  text-transform: none;
  font-size: 16px;
  font-weight: 600;
}
.btn.btn-help.blue {
  color: #083a6b;
}
.btn.btn-help.grey {
  color: #6e6e6e;
}
.btn.btn-help.icon {
  top: 0;
}
.btn.btn-help.icon i {
  color: #6e6e6e;
  font-size: 25px;
}
@media (min-width: 1200px) {
}
.btn.btn-back {
  position: absolute;
  top: 4px;
  left: 0;
  color: #fff;
  text-transform: none;
  font-size: 14px;
}
.btn.btn-back i {
  font-size: 20px;
  vertical-align: -2px;
  padding: 0 3px 0 0;
}
.btn.btn-back.blue {
  color: #083a6b;
}
.btn.btn-back.icon {
  top: 5px;
}
.btn.btn-back.icon i {
  color: #6e6e6e;
  font-size: 20px;
}
@media (min-width: 1200px) {
  .btn.btn-back {
    top: 44px;
  }
}
.btn.btn-bottom {
  margin-bottom: 50px;
}
.btn.btn-small {
  padding: 5px 15px;
  color: #fff;
  font-size: 14px;
  height: 36px;
  font-weight: 600;
}
.btn.btn-small.btn-outline {
  color: #5c5c5c;
}
.two-lines-btn .btn.btn-small {
  height: auto;
  min-height: 56px;
  white-space: normal;
}
@media (max-width: 768px) {
  .btn.btn-small.btn-sm-block {
    max-width: 100%;
    width: 100%;
  }
}
.btn.btn-max-300 {
  max-width: 300px;
  min-width: 200px;
}
.btn.btn-trans-border {
  height: 44px;
  border: 1px solid #fff;
  background: none;
  width: 100%;
  font-size: 16px;
  color: #fff;
  line-height: 44px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0;
}
.btn.btn-white-bordered {
  background: #fff;
  border: 2px solid #6e6e6e;
  padding: 7px 14px;
  border-radius: 3px;
  font-size: 14px;
}
.btn.btn-white-bordered.active,
.btn.btn-white-bordered:active,
.btn.btn-white-bordered:focus {
  box-shadow: none !important;
  color: #09ace4;
  background: #d6ebf9;
  border: 2px solid #09ace4;
}
.btn.btn-white-bordered.disabled,
.btn.btn-white-bordered :disabled,
.btn.btn-white-bordered[disabled] {
  border: 2px solid #6e6e6e !important;
  color: #7c7c7c !important;
  background: #fff;
}
.btn.btn-white-bordered.big {
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  font-family: 'ProximaNova';
  font-weight: 600;
  text-align: center;
  width: 100%;
  text-transform: none;
  padding: 0;
}
.btn.btn-white-bordered.light {
  font-size: 16px;
  line-height: 16px;
  border-width: 1px;
  border-color: #e2e2e2;
  padding: 14px 25px;
  color: #09ace4;
}
.btn.btn-checkbox {
  position: relative;
  width: 100%;
  max-width: 100% !important;
}
.btn.btn-checkbox.selected:after {
  opacity: 1;
}
.btn.btn-checkbox:focus,
.btn.btn-checkbox:active {
  outline: 0;
  box-shadow: 0;
  background: none;
}
@media (max-width: 480px) {
  .btn.btn-checkbox {
    font-size: 15px;
  }
}
.btn.btn-round-plus {
  min-width: 36px;
  min-height: 36px;
  height: 36px;
  width: auto;
  line-height: 36px;
  border-radius: 36px;
  background: #278600;
  color: #fff;
  font-size: 16px;
  padding: 0 15px 0 10px;
  -webkit-transition: ease 200ms all;
  -moz-transition: ease 200ms all;
  -ms-transition: ease 200ms all;
  -o-transition: ease 200ms all;
  /** Fix spinner position */
}
.btn.btn-round-plus i {
  vertical-align: -2px;
  font-size: 17px;
}
.btn.btn-round-plus .btn-text {
  margin: 0 0 0 10px;
  display: inline;
}
.btn.btn-round-plus.btn-big {
  min-width: 48px;
  min-height: 48px;
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-radius: 48px;
  padding: 0;
  background: #09ace4;
}
.btn.btn-round-plus.btn-big i {
  font-size: 24px;
  vertical-align: -6px;
}
.btn.btn-round-plus.btn-big:hover,
.btn.btn-round-plus.btn-big:active,
.btn.btn-round-plus.btn-big:focus,
.btn.btn-round-plus.btn-big.active {
  background: #0899cb;
}
.btn.btn-round-plus:hover {
  width: auto;
  background: #097200;
}
.btn.btn-round-plus.ladda-button {
  overflow: visible;
}
.btn.ladda-button[data-style='zoom-in'][data-loading] .ladda-label {
  opacity: 0;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}
.btn.btn-inline-block {
  display: inline-block !important;
}
.btn.btn-white-border {
  border: 1px solid #fff !important;
}
.btn.btn-inline-link {
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  background: none !important;
  font-size: inherit;
  padding: 0;
}
.btn.btn-inline-link:active,
.btn.btn-inline-link:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.btn-block {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.btn.btn-pill {
  height: 48px;
  line-height: 48px;
  padding: 0 15px;
  display: inline-block;
  border: 1px solid #d8d8d8;
  font-size: 15px;
  background: #fff;
  color: #6e6e6e;
  border-radius: 50px;
}
.btn.btn-pill.btn-small {
  height: 36px;
  line-height: 36px;
}
.btn.btn-pill:active,
.btn.btn-pill.selected {
  border: 1px solid #5c5c5c;
  color: #5c5c5c;
}
.btn.btn-round-icon {
  border-radius: 50px;
  width: 36px;
  height: 36px;
  line-height: 42px;
  font-size: 20px;
  padding: 0;
  box-shadow: 0 0 0 0 #eaeaea inset;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  -ms-transition: all 400ms ease;
  -o-transition: all 400ms ease;
}
.btn.btn-round-icon:hover,
.btn.btn-round-icon:active,
.btn.btn-round-icon.active {
  box-shadow: 0 0 0 100px #eaeaea inset;
}
.btn.btn-outline-pill {
  border-radius: 26px;
  height: 26px;
  line-height: 26px;
  padding: 0 30px;
  font-size: 14px;
  font-weight: normal;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #6e6e6e;
  color: #6e6e6e;
  box-shadow: 0 0 0 #6e6e6e;
  -webkit-transition: box-shadow 200ms ease;
  -moz-transition: box-shadow 200ms ease;
  -ms-transition: box-shadow 200ms ease;
  -o-transition: box-shadow 200ms ease;
}
.btn.btn-outline-pill:hover {
  background: #6e6e6e;
  color: #fff;
}
.btn.two-lines-btn {
  height: auto !important;
  line-height: normal !important;
  padding: 10px 20px !important;
  white-space: normal !important;
  max-width: 100% !important;
}
.btn.disabled,
.btn :disabled,
.btn[disabled] {
  opacity: 0.4;
}
.btn-group-centered {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
@media (max-width: 767px) {
  .btn-group-centered .btn-wrap:only-of-type {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
.btn-group-centered > .btn,
.btn-group-centered > .btn-wrap > .btn {
  display: inline-block !important;
  margin: 0 2px;
}
@media (max-width: 767px) {
  .btn-group-centered > .btn,
  .btn-group-centered > .btn-wrap > .btn {
    min-width: 48%;
    max-width: 48%;
    margin: 0 1%;
  }
}
@media (max-width: 767px) {
  .btn-group-centered {
    width: 100%;
  }
}
@font-face {
  font-family: 'bookmd_icons';
  src: url('~@getvim/components-base-css/src/fonts/bookmd_icons.eot?b5o9ll');
  src: url('~@getvim/components-base-css/src/fonts/bookmd_icons.eot?#iefix-b5o9ll') format('embedded-opentype'), url('~@getvim/components-base-css/src/fonts/bookmd_icons.ttf?b5o9ll') format('truetype'), url('~@getvim/components-base-css/src/fonts/bookmd_icons.woff?b5o9ll') format('woff'), url('~@getvim/components-base-css/src/fonts/bookmd_icons.svg?b5o9ll#bookmd_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  font-family: 'bookmd_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-user-doctor-2:before {
  content: "\e991";
}
.icon-award-2:before {
  content: "\e992";
}
.icon-condition-heart-2:before {
  content: "\e993";
}
.icon-syringe-2:before {
  content: "\e994";
}
.icon-gender-2:before {
  content: "\e995";
}
.icon-bell:before {
  content: "\e996";
}
.icon-paper-airplane:before {
  content: "\e997";
}
.icon-age-2:before {
  content: "\e998";
}
.icon-award:before {
  content: "\e999";
}
.icon-door-f:before {
  content: "\e99a";
}
.icon-lightning-e:before {
  content: "\e99b";
}
.icon-door:before {
  content: "\e98c";
}
.icon-hourglass-middle:before {
  content: "\e98d";
}
.icon-hourglass-bottom:before {
  content: "\e98e";
}
.icon-hourglass-top:before {
  content: "\e98f";
}
.icon-user-doctor:before {
  content: "\e990";
}
.icon-video-cam:before {
  content: "\e988";
}
.icon-envelope-c:before {
  content: "\e989";
}
.icon-calendar-check-2:before {
  content: "\e98a";
}
.icon-chevron-2:before {
  content: "\e98b";
}
.icon-clipboard:before {
  content: '\e982';
}
.icon-syringe:before {
  content: '\e983';
}
.icon-age:before {
  content: '\e984';
}
.icon-condition-heart:before {
  content: '\e985';
}
.icon-gender:before {
  content: '\e986';
}
.icon-stethoscope:before {
  content: '\e987';
}
.icon-send-arrow:before {
  content: '\e981';
}
.icon-message-b:before {
  content: '\e97e';
}
.icon-map-pin-v:before {
  content: '\e97f';
}
.icon-map-pin-x:before {
  content: '\e980';
}
.icon-team:before {
  content: '\e973';
}
.icon-dislike:before {
  content: '\e974';
}
.icon-like:before {
  content: '\e975';
}
.icon-user-re:before {
  content: '\e976';
}
.icon-ext-arrow:before {
  content: '\e977';
}
.icon-clinic-2:before {
  content: '\e978';
}
.icon-import-c:before {
  content: '\e972';
}
.icon-calendar-graph:before {
  content: '\e970';
}
.icon-graphs:before {
  content: '\e971';
}
.icon-user-c:before {
  content: '\e96e';
}
.icon-calendar-check:before {
  content: '\e96f';
}
.icon-eye-2:before {
  content: '\e96c';
}
.icon-export:before {
  content: '\e96d';
}
.icon-calc:before {
  content: '\e96b';
  color: #545454;
}
.icon-shield-q:before {
  content: '\e96a';
}
.icon-ban:before {
  content: '\e969';
}
.icon-exclamation:before {
  content: '\e968';
}
.icon-medical-team:before {
  content: '\e967';
}
.icon-refresh:before {
  content: '\e966';
}
.icon-flag:before {
  content: '\e964';
}
.icon-user-id:before {
  content: '\e965';
}
.icon-play:before {
  content: '\e963';
}
.icon-check-in:before {
  content: '\e962';
}
.icon-user-r-stars:before {
  content: '\e960';
}
.icon-calendar-stars:before {
  content: '\e95f';
}
.icon-star-sh:before {
  content: '\e95e';
}
.icon-star-up:before {
  content: '\e961';
}
.icon-dots:before {
  content: '\e95d';
  color: #545454;
}
.icon-clock:before {
  content: '\e95c';
}
.icon-lightning:before {
  content: '\e95b';
}
.icon-warning:before {
  content: '\e957';
}
.icon-check-s-2:before {
  content: '\e958';
}
.icon-user-r:before {
  content: '\e959';
}
.icon-note-h-e:before {
  content: '\e95a';
}
.icon-home-3:before {
  content: '\e953';
}
.icon-places:before {
  content: '\e954';
}
.icon-help-w:before {
  content: '\e955';
}
.icon-heart-vim-e:before {
  content: '\e956';
}
.icon-heart-vim:before {
  content: '\e952';
}
.icon-globe:before {
  content: '\e951';
}
.icon-add-member:before {
  content: '\e950';
}
.icon-notification-s:before {
  content: '\e94f';
}
.icon-calendar-sm:before {
  content: '\e94d';
}
.icon-building:before {
  content: '\e94c';
}
.icon-user-2:before {
  content: '\e94b';
}
.icon-medication:before {
  content: '\e94a';
}
.icon-browser:before {
  content: '\e948';
}
.icon-iphone:before {
  content: '\e949';
}
.icon-heart-key:before {
  content: '\e93f';
}
.icon-cog-s-2:before {
  content: '\e940';
}
.icon-md-circle:before {
  content: '\e941';
}
.icon-speak-heart:before {
  content: '\e942';
}
.icon-clinic:before {
  content: '\e943';
}
.icon-hospital-2:before {
  content: '\e944';
}
.icon-pill:before {
  content: '\e945';
}
.icon-tube:before {
  content: '\e946';
}
.icon-radiology:before {
  content: '\e947';
}
.icon-shield-check:before {
  content: '\e93e';
}
.icon-heart-e:before {
  content: '\e93a';
}
.icon-heart-f:before {
  content: '\e93b';
}
.icon-check-circle-e:before {
  content: '\e93c';
}
.icon-check-circle-f:before {
  content: '\e93d';
}
.icon-help-c-2:before {
  content: '\e937';
}
.icon-cog-s:before {
  content: '\e938';
}
.icon-search-2:before {
  content: '\e939';
}
.icon-link:before {
  content: '\e934';
}
.icon-envelope-b:before {
  content: '\e935';
}
.icon-speak-b:before {
  content: '\e936';
}
.icon-eye:before {
  content: '\e933';
}
.icon-back-arrow:before {
  content: '\e932';
}
.icon-menu-bars:before {
  content: '\e929';
}
.icon-asterisk:before {
  content: '\e92a';
}
.icon-phone-f:before {
  content: '\e92b';
}
.icon-ext-link:before {
  content: '\e92c';
}
.icon-shield:before {
  content: '\e92d';
}
.icon-map-pin-e:before {
  content: '\e92e';
}
.icon-home-2:before {
  content: '\e92f';
}
.icon-suitcase:before {
  content: '\e930';
}
.icon-location-cross:before {
  content: '\e931';
}
.icon-ul:before {
  content: '\e927';
}
.icon-cog:before {
  content: '\e928';
}
.icon-calendar-o:before {
  content: '\e91e';
}
.icon-note-h:before {
  content: '\e91f';
}
.icon-check-s:before {
  content: '\e920';
}
.icon-pie-chart:before {
  content: '\e921';
}
.icon-quote:before {
  content: '\e922';
}
.icon-user:before {
  content: '\e923';
}
.icon-plus-s:before {
  content: '\e924';
}
.icon-notebook:before {
  content: '\e925';
}
.icon-arrow-s:before {
  content: '\e926';
}
.icon-filter-sliders:before {
  content: '\e91d';
}
.icon-arrow-best:before {
  content: '\e91c';
}
.icon-chat-f:before {
  content: '\e91a';
}
.icon-chat:before {
  content: '\e91b';
}
.icon-help-center:before {
  content: '\e919';
}
.icon-filter:before {
  content: '\e916';
}
.icon-left-arrow-w:before {
  content: '\e917';
}
.icon-right-arrow-w:before {
  content: '\e918';
}
.icon-envelope:before {
  content: '\e800';
}
.icon-search:before {
  content: '\e60b';
}
.icon-notification:before {
  content: '\e60c';
}
.icon-help:before {
  content: '\e600';
}
.icon-home:before {
  content: '\e910';
}
.icon-attachment:before {
  content: '\e911';
}
.icon-print:before {
  content: '\e912';
}
.icon-hospital:before {
  content: '\e913';
}
.icon-md-bag:before {
  content: '\e914';
}
.icon-face:before {
  content: '\e915';
}
.icon-life-ring:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e90a';
}
.icon-folder:before {
  content: '\e90b';
}
.icon-chevron-up:before {
  content: '\e90c';
}
.icon-chevron-right:before {
  content: '\e90d';
}
.icon-chevron-left:before {
  content: '\e90e';
}
.icon-chevron-down:before {
  content: '\e90f';
}
.icon-map-pin:before {
  content: '\e908';
}
.icon-facility:before {
  content: '\e905';
}
.icon-hat:before {
  content: '\e906';
}
.icon-staff:before {
  content: '\e907';
}
.icon-datepicker-s:before {
  content: '\e904';
}
.icon-help-c:before {
  content: '\e903';
}
.icon-download:before {
  content: '\e902';
}
.icon-plus-fat:before {
  content: '\e901';
}
.icon-pencil:before {
  content: '\e900';
}
.icon-plus:before {
  content: '\e616';
}
.icon-info-f:before {
  content: '\e615';
}
.icon-info:before {
  content: '\e614';
}
.icon-date-picker:before {
  content: '\e613';
}
.icon-fax:before {
  content: '\e612';
}
.icon-trash:before {
  content: '\e611';
}
.icon-calendar-fav:before {
  content: '\e60d';
}
.icon-dropdown-arrow:before {
  content: '\e60e';
}
.icon-x-2:before {
  content: '\e60f';
}
.icon-search-circle:before {
  content: '\e610';
}
.icon-speak:before {
  content: '\e607';
}
.icon-phone:before {
  content: '\e608';
}
.icon-left-arrow:before {
  content: '\e609';
}
.icon-x:before {
  content: '\e60a';
}
.icon-night:before {
  content: '\e601';
}
.icon-evening:before {
  content: '\e602';
}
.icon-afternoon:before {
  content: '\e603';
}
.icon-morning:before {
  content: '\e604';
}
.icon-sunrise:before {
  content: '\e605';
}
.icon-noon:before {
  content: '\e606';
}
.icon-ticket:before {
  content: '\e94e';
}
.icon-tumblr2:before {
  content: '\eabc';
}
.icon-happy-face:before {
  content: '\e979';
}
.icon-smiley-face:before {
  content: '\e97a';
}
.icon-neutral-face:before {
  content: '\e97b';
}
.icon-sad-face:before {
  content: '\e97c';
}
.icon-frown-face:before {
  content: '\e97d';
}
.progress {
  box-shadow: none;
}
.progress.loading-progress {
  height: 10px;
  border-radius: 8px;
  background: #002141;
}
.progress.loading-progress .progress-bar {
  border-radius: 0 8px 8px 0;
  background: #09ace4;
}
.progress.small-progress {
  height: 6px;
  border-radius: 0;
  box-shadow: none;
  background: #d8d8d8;
}
.progress.trans-progress {
  background: none;
  height: 22px;
}
.progress.trans-progress .progress-bar.progress-bar-stripes {
  background: url('~@getvim/components-base-css/src/images/stripes-trans-bg.png') repeat;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar.progress-bar-warning {
  background: #ffb22f;
}
.progress .progress-bar.progress-bar-success {
  background: #278600;
}
.progress.brand-color-progress {
  background: #d8d8d8;
}
.progress-wrap.success .progress {
  background: #278600;
}
.progress-wrap.success .progress-bar {
  background: #278600;
}
.progress-wrap.success .progress-text {
  color: #278600;
}
.progress-wrap.error .progress {
  background: #b12704;
}
.progress-wrap.error .progress-bar {
  background: #b12704;
}
.progress-wrap.error .progress-text {
  color: #b12704;
}
.pagination {
  margin: 0;
}
.pagination.pagination-lg > li a,
.pagination.pagination-lg > li div {
  padding: 0;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #32333C;
  border: 1px solid #e2e2e2;
  display: inline-block;
  background-color: white;
}
.pagination.pagination-lg > li.active div,
.pagination.pagination-lg > li.active > span {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
  font-size: 16px;
  display: inline-block;
  height: 48px;
  width: 48px;
  padding: 0;
  line-height: 48px;
  text-align: center;
}
.pagination.pagination-lg > li.disabled a,
.pagination.pagination-lg > li.disabled div {
  opacity: 0.5;
}
.pagination.pagination-lg > li:first-child a,
.pagination.pagination-lg > li:first-child div {
  font-size: 0;
}
.pagination.pagination-lg > li:first-child a:before,
.pagination.pagination-lg > li:first-child div:before {
  display: inline-block;
  content: '\e90e';
  font-family: 'bookmd_icons';
  font-size: 10px;
  color: #32333C;
}
.pagination.pagination-lg > li:last-child a,
.pagination.pagination-lg > li:last-child div {
  font-size: 0;
}
.pagination.pagination-lg > li:last-child a:before,
.pagination.pagination-lg > li:last-child div:before {
  display: inline-block;
  content: '\e90d';
  font-family: 'bookmd_icons';
  font-size: 10px;
  color: #32333C;
}
.pagination.pagination-lg.pagination-vim li > a:first-child,
.pagination.pagination-lg.pagination-vim li > div:first-child,
.pagination.pagination-lg.pagination-vim li > a:last-child,
.pagination.pagination-lg.pagination-vim li > div:last-child {
  font-size: 16px;
}
.pagination.pagination-lg.pagination-vim li > a:first-child:before,
.pagination.pagination-lg.pagination-vim li > div:first-child:before,
.pagination.pagination-lg.pagination-vim li > a:last-child:before,
.pagination.pagination-lg.pagination-vim li > div:last-child:before {
  content: '';
}
.pagination.previous > li > div,
.pagination.next > li > div {
  padding: 0;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #5c5c5c;
  border: 1px solid #e2e2e2;
  display: inline-block;
  background-color: white;
}
.pagination.previous > li > div {
  border-radius: 6px 0 0 6px;
}
.pagination.next > li > div {
  border-radius: 0 6px 6px 0;
}
.basic-dropdown,
.dropdown {
  display: inline-block;
  position: relative;
}
.basic-dropdown .dropdown-toggle .caret,
.dropdown .dropdown-toggle .caret {
  border: none;
  height: auto;
  width: auto;
  line-height: 14px;
}
.basic-dropdown .dropdown-toggle .caret:after,
.dropdown .dropdown-toggle .caret:after {
  display: inline-block;
  content: '\e90f';
  font-family: 'bookmd_icons';
  font-size: 10px;
  margin: 0 0 0 5px;
}
.basic-dropdown .dropdown-menu,
.dropdown .dropdown-menu {
  left: auto;
  right: -10px;
  min-width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  border-color: #e2e2e2;
}
.basic-dropdown .dropdown-menu > li > a,
.dropdown .dropdown-menu > li > a {
  line-height: 36px;
  font-size: 16px;
  padding: 0 20px;
  color: #6e6e6e;
}
.basic-dropdown .dropdown-menu > li > a:hover,
.dropdown .dropdown-menu > li > a:hover,
.basic-dropdown .dropdown-menu > li > a:active,
.dropdown .dropdown-menu > li > a:active,
.basic-dropdown .dropdown-menu > li > a:focus,
.dropdown .dropdown-menu > li > a:focus {
  color: #fff;
}
@media (min-width: 769px) {
  .basic-dropdown .dropdown-menu > li.small-screens-only,
  .dropdown .dropdown-menu > li.small-screens-only {
    display: none;
  }
}
.basic-dropdown .dropdown-menu > li.dropdown-header,
.dropdown .dropdown-menu > li.dropdown-header {
  width: 100% !important;
  height: 56px;
  line-height: 56px;
  padding: 0 56px;
  text-align: left;
  position: relative;
}
.basic-dropdown .dropdown-menu > li.dropdown-header a,
.dropdown .dropdown-menu > li.dropdown-header a {
  padding: 0 !important;
  line-height: 56px;
}
.basic-dropdown .dropdown-menu > li.dropdown-header a:hover,
.dropdown .dropdown-menu > li.dropdown-header a:hover,
.basic-dropdown .dropdown-menu > li.dropdown-header a:active,
.dropdown .dropdown-menu > li.dropdown-header a:active,
.basic-dropdown .dropdown-menu > li.dropdown-header a:focus,
.dropdown .dropdown-menu > li.dropdown-header a:focus {
  background: #fff;
}
.basic-dropdown .dropdown-menu > li.selected > a,
.dropdown .dropdown-menu > li.selected > a {
  box-shadow: 6px 0 0 #09ace4 inset;
}
.basic-dropdown .dropdown-menu:before,
.dropdown .dropdown-menu:before,
.basic-dropdown .dropdown-menu:after,
.dropdown .dropdown-menu:after {
  bottom: 100%;
  left: auto;
  right: 10px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.basic-dropdown .dropdown-menu:after,
.dropdown .dropdown-menu:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
}
.basic-dropdown .dropdown-menu:before,
.dropdown .dropdown-menu:before {
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #e2e2e2;
  border-width: 10px;
  right: 8px;
}
@media (max-width: 767px) {
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-toggle .caret,
  .dropdown.xs-fullscreen-dropdown .dropdown-toggle .caret {
    display: none;
  }
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a {
  min-height: 56px;
  line-height: 56px;
  color: #545454;
  font-size: 18px;
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > i,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > i,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > .svg-icon,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > .svg-icon,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > a > i,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > a > i {
  font-size: 19px;
  display: inline-block;
  vertical-align: -2px;
  margin: 0 15px 0 0;
  height: 16px;
  width: 20px;
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > a,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a > a {
  display: inline-block;
  width: 100%;
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus {
  color: #fff;
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover .svg-icon .fill-color-brand-main,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover .svg-icon .fill-color-brand-main,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active .svg-icon .fill-color-brand-main,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active .svg-icon .fill-color-brand-main,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus .svg-icon .fill-color-brand-main,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus .svg-icon .fill-color-brand-main {
  fill: #fff !important;
}
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover .svg-icon .fill-color-brand-secondary,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:hover .svg-icon .fill-color-brand-secondary,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active .svg-icon .fill-color-brand-secondary,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:active .svg-icon .fill-color-brand-secondary,
.basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus .svg-icon .fill-color-brand-secondary,
.dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a:focus .svg-icon .fill-color-brand-secondary {
  fill: #fff !important;
}
@media (max-width: 767px) {
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu {
    position: fixed;
    width: calc(100% -  40px) !important;
    height: 100%;
    overflow: auto;
    top: 0;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
    border: none;
  }
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li.dropdown-header a,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu > li.dropdown-header a {
    line-height: 56px;
    height: 56px;
  }
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu > li > a {
    min-height: 48px;
    line-height: 48px;
    font-size: 18px;
  }
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn,
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn {
    display: block;
    position: absolute;
    top: 0;
    font-size: 20px;
    text-align: center;
    color: #5c5c5c;
    line-height: 56px;
    height: 56px;
    width: 56px;
  }
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn.left,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn.left,
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn.left,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn.left {
    left: 0;
  }
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn.right,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-back-btn.right,
  .basic-dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn.right,
  .dropdown.xs-fullscreen-dropdown .dropdown-menu .dropdown-close-btn.right {
    right: 0;
  }
}
.basic-dropdown.scrollable.open .dropdown-toggle,
.dropdown.scrollable.open .dropdown-toggle {
  z-index: 2000;
}
.basic-dropdown.scrollable.open .dropdown-toggle:before,
.dropdown.scrollable.open .dropdown-toggle:before,
.basic-dropdown.scrollable.open .dropdown-toggle:after,
.dropdown.scrollable.open .dropdown-toggle:after {
  bottom: -14px;
  left: auto;
  right: -3px;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  z-index: 2000;
}
.basic-dropdown.scrollable.open .dropdown-toggle:after,
.dropdown.scrollable.open .dropdown-toggle:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
}
.basic-dropdown.scrollable.open .dropdown-toggle:before,
.dropdown.scrollable.open .dropdown-toggle:before {
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #e2e2e2;
  border-width: 10px;
  right: -5px;
}
.basic-dropdown.scrollable.open .dropdown-toggle:hover,
.dropdown.scrollable.open .dropdown-toggle:hover {
  z-index: 2000;
}
.basic-dropdown.scrollable .dropdown-menu,
.dropdown.scrollable .dropdown-menu {
  max-height: 400px;
  overflow: auto;
}
.basic-dropdown.open .dropdown-toggle,
.dropdown.open .dropdown-toggle {
  box-shadow: none !important;
}
.basic-dropdown.pill-btn-dropdown .btn.dropdown-toggle,
.dropdown.pill-btn-dropdown .btn.dropdown-toggle {
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  color: #5c5c5c;
  text-align: left;
  padding: 0 35px 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.basic-dropdown.pill-btn-dropdown .btn.dropdown-toggle:before,
.dropdown.pill-btn-dropdown .btn.dropdown-toggle:before {
  content: '\e90f';
  right: 10px;
  font-family: 'bookmd_icons';
  font-size: 12px;
  color: #5c5c5c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.basic-dropdown.pill-btn-dropdown .dropdown-menu,
.dropdown.pill-btn-dropdown .dropdown-menu {
  padding: 5px 0;
}
.basic-dropdown.pill-btn-dropdown .dropdown-menu li a,
.dropdown.pill-btn-dropdown .dropdown-menu li a {
  line-height: normal;
  padding: 10px 20px;
}
.basic-dropdown.pill-btn-dropdown .dropdown-menu li a .item-title,
.dropdown.pill-btn-dropdown .dropdown-menu li a .item-title {
  color: #5c5c5c;
}
.basic-dropdown.pill-btn-dropdown .dropdown-menu li a:active .item-title,
.dropdown.pill-btn-dropdown .dropdown-menu li a:active .item-title,
.basic-dropdown.pill-btn-dropdown .dropdown-menu li a:hover .item-title,
.dropdown.pill-btn-dropdown .dropdown-menu li a:hover .item-title,
.basic-dropdown.pill-btn-dropdown .dropdown-menu li a:focus .item-title,
.dropdown.pill-btn-dropdown .dropdown-menu li a:focus .item-title {
  color: #fff;
}
.basic-dropdown.pill-btn-dropdown .dropdown-menu li:hover a,
.dropdown.pill-btn-dropdown .dropdown-menu li:hover a {
  background: none;
}
.basic-dropdown.pill-btn-dropdown.open .btn.dropdown-toggle,
.dropdown.pill-btn-dropdown.open .btn.dropdown-toggle {
  background: #eaeaea;
}
.basic-dropdown.left-border-menu .dropdown-menu li,
.dropdown.left-border-menu .dropdown-menu li {
  line-height: 36px;
}
.basic-dropdown.left-border-menu .dropdown-menu li a,
.dropdown.left-border-menu .dropdown-menu li a {
  min-height: 36px;
  line-height: 36px;
}
.basic-dropdown.left-border-menu .dropdown-menu li a:active,
.dropdown.left-border-menu .dropdown-menu li a:active,
.basic-dropdown.left-border-menu .dropdown-menu li a:hover,
.dropdown.left-border-menu .dropdown-menu li a:hover,
.basic-dropdown.left-border-menu .dropdown-menu li a:focus,
.dropdown.left-border-menu .dropdown-menu li a:focus {
  background: #fff;
  color: #1f1f1f;
}
.basic-dropdown.left-border-menu .dropdown-menu li.active a,
.dropdown.left-border-menu .dropdown-menu li.active a {
  background: #fff;
  color: #1f1f1f;
}
@media (max-width: 767px) {
  .basic-dropdown.left-border-menu .dropdown-menu li,
  .dropdown.left-border-menu .dropdown-menu li {
    padding: 6px 0;
  }
  .basic-dropdown.left-border-menu .dropdown-menu li.dropdown-header,
  .dropdown.left-border-menu .dropdown-menu li.dropdown-header {
    padding: 0 0 0 56px;
  }
}
.popover {
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 1px;
  font-family: 'proximaNova', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
}
.popover .arrow {
  top: 100%;
}
.popover .arrow:after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ffffff transparent transparent transparent;
  top: -11px;
  left: -10px;
}
.popover .arrow:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -11px;
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #e2e2e2 transparent transparent transparent;
}
.big-search-input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 0 45px 0 20px;
  font-size: 16px;
  color: #5c5c5c;
  margin: 30px 0;
  position: relative;
}
.big-search-input + .search-inner-icon {
  right: 30px;
  font-size: 20px;
  color: #6e6e6e;
  z-index: 500;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.big-search-input .btn .search-inner-icon {
  position: relative;
  vertical-align: -2px;
  padding: 0 5px 0 3px;
}
.big-search-input .validation-message {
  margin-top: 12px;
}
.big-search-input ~ .clear-input-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 11px;
  width: 48px;
  height: 48px;
  line-height: 52px;
  text-align: center;
  color: #5c5c5c;
  z-index: 10;
  cursor: pointer;
}
.big-search-input.multiple-inputs {
  padding: 0;
}
.big-search-input.multiple-inputs .clean-input,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main {
  border-bottom: none;
  border-right: 1px solid #e2e2e2 !important;
  height: 48px;
  margin: 0;
  padding: 0 15px;
  font-size: 16px;
}
.big-search-input.multiple-inputs .clean-input + label,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main + label {
  left: 15px;
  height: 50px;
  line-height: 50px;
}
.big-search-input.multiple-inputs .clean-input + label.input-active,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main + label.input-active {
  height: auto;
  width: auto;
  line-height: normal;
  top: -7px;
  display: inline-block;
  box-shadow: 0 -7px 0 #fff inset;
  padding: 0 5px;
  margin-left: -5px;
  z-index: 2;
}
.big-search-input.multiple-inputs .clean-input:active + label,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main:active + label,
.big-search-input.multiple-inputs .clean-input:focus + label,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main:focus + label {
  height: auto;
  width: auto;
  line-height: normal;
  top: -7px;
  display: inline-block;
  box-shadow: 0 -7px 0 #fff inset;
  padding: 0 5px;
  margin-left: -5px;
  z-index: 2;
}
.big-search-input.multiple-inputs .clean-input.last-input,
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main.last-input {
  border-right: none !important;
  padding: 0 45px 0 15px;
}
.big-search-input.multiple-inputs .bootstrap-typeahead-input-main {
  border-width: 0;
  box-shadow: none;
}
.big-search-input.multiple-inputs.no-right-border .Select.clean-input {
  border-right: none !important;
}
.big-search-input.multiple-inputs input[type='text']::-ms-clear {
  display: none;
}
.big-search-input.multiple-inputs > .form-group > div.clean-input.Select {
  border-right: none !important;
}
.big-search-input.multiple-inputs > .form-group > div.clean-input.Select .Select-arrow {
  display: none;
}
.big-search-input.multiple-inputs.show-arrow > .form-group > div.clean-input.Select .Select-arrow {
  display: inline-block !important;
}
.big-search-input.multiple-inputs.hide-arrow .Select-arrow-zone {
  display: none !important;
}
.big-search-input.multiple-inputs.right-btn-padding {
  padding-right: 100px;
}
.big-search-input.multiple-inputs.right-btn-padding .select-right-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  min-width: 0;
  max-width: 100%;
  border-radius: 0 4px 4px 0;
  border-width: 0 0 0 1px;
}
.big-search-input.multiple-inputs.right-btn-padding .select-right-btn:active,
.big-search-input.multiple-inputs.right-btn-padding .select-right-btn:focus {
  border-color: #e2e2e2;
}
.big-search-input.multiple-inputs.left-search-icon .Select.clean-input,
.big-search-input.multiple-inputs.left-search-icon .bootstrap-typeahead-input-main {
  padding: 0 15px 0 45px;
}
.big-search-input.multiple-inputs.left-search-icon + .search-inner-icon {
  right: auto;
  left: 15px;
  font-size: 20px;
  color: #6e6e6e;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.big-search-input.multiple-inputs.left-search-icon .Select-menu-outer {
  margin-left: -45px;
}
.big-search-input.multiple-inputs.left-search-icon ~ label.typeahead-label {
  position: absolute;
  top: 0;
  left: 45px;
  line-height: 50px;
  font-weight: normal;
  font-size: 16px;
  display: inline-block;
  padding: 0 35px 0 45px;
  margin-left: -40px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
}
.big-search-input.multiple-inputs.left-search-icon ~ label.typeahead-label.input-active {
  height: auto;
  width: auto;
  line-height: normal;
  top: -7px;
  margin-left: -2px;
  z-index: 2;
  background: #fff;
  font-size: 11px;
  padding: 0 5px;
  color: #5c5c5c;
}
.big-search-input.multiple-inputs.right-clear-icon .bootstrap-typeahead-input-main {
  padding-right: 35px;
}
.big-search-input.multiple-inputs .clean-input.Select > .Select-control {
  margin-top: -1px;
  font-size: 16px;
  color: #083a6b;
  padding: 0;
  border-right: none;
  border-bottom: none;
  line-height: 48px;
}
.big-search-input.multiple-inputs .clean-input.Select > .Select-control .Select-value {
  line-height: 52px;
  padding: 0;
}
.big-search-input.multiple-inputs .clean-input.Select > .Select-control .Select-value .Select-value-label {
  font-weight: 500;
  color: #5c5c5c;
}
.big-search-input.multiple-inputs .clean-input.Select > .Select-control .Select-arrow:after {
  vertical-align: -2px;
}
.big-search-input.multiple-inputs .clean-input.Select > .Select-control:active,
.big-search-input.multiple-inputs .clean-input.Select > .Select-control:focus {
  border-bottom: none !important;
}
.big-search-input.multiple-inputs .clean-input.Select.grey-select {
  border-radius: 6px 0 0 6px !important;
  height: 48px;
  margin: 1px 0 0 0;
}
.big-search-input.multiple-inputs .clean-input.Select.grey-select > .Select-control .Select-value .Select-value-label {
  font-weight: 600;
  color: #6e6e6e;
}
.big-search-input.multiple-inputs .clean-input.Select:focus,
.big-search-input.multiple-inputs .clean-input.Select.is-focused,
.big-search-input.multiple-inputs .clean-input.Select:active {
  border-bottom: none !important;
}
.big-search-input.multiple-inputs .clean-input.Select:focus .Select-control,
.big-search-input.multiple-inputs .clean-input.Select.is-focused .Select-control,
.big-search-input.multiple-inputs .clean-input.Select:active .Select-control,
.big-search-input.multiple-inputs .clean-input.Select:focus .Select-input,
.big-search-input.multiple-inputs .clean-input.Select.is-focused .Select-input,
.big-search-input.multiple-inputs .clean-input.Select:active .Select-input {
  background: none;
}
.big-search-input.multiple-inputs .Select-input {
  height: 50px;
}
.big-search-input.multiple-inputs .Select-placeholder {
  line-height: 52px;
  font-size: 16px;
  padding: 0;
}
.big-search-input.multiple-inputs .big-search-btn-padding {
  padding: 0 138px 0 0;
}
.big-search-input input:-webkit-autofill,
.big-search-input textarea:-webkit-autofill,
.big-search-input select:-webkit-autofill {
  vertical-align: 1px;
}
.big-search-input .clean-input.Select .Select-menu-outer {
  right: 0;
}
.big-search-input-options {
  position: relative;
}
.big-search-input-options .big-search-input.multiple-inputs.select-main-options {
  position: absolute;
  left: 15px;
  top: 0;
}
.big-search-input-options .big-search-input.multiple-inputs.select-main-options .Select.clean-input {
  padding-right: 0;
}
.big-search-input-options .big-search-input.multiple-inputs.select-main-options .Select.clean-input .Select-arrow-zone {
  width: 46px;
  padding: 0;
}
.big-search-input-options .big-search-input.multiple-inputs.select-main-options .Select.clean-input .Select-arrow {
  display: inline-block;
  background: #083a6b;
  width: 46px;
  height: 48px;
  border-radius: 0 6px 6px 0;
}
.big-search-input-options .big-search-input.multiple-inputs.select-main-options .Select.clean-input .Select-arrow:after {
  color: #fff;
  content: '\f0d7';
  font-family: 'FontAwesome';
  font-size: 22px;
  vertical-align: -5px;
}
.big-search-input-options.requests,
.big-search-input-options.physician {
  padding-left: 210px;
}
.big-search-input-options.requests .big-search-input.multiple-inputs.select-main-options,
.big-search-input-options.physician .big-search-input.multiple-inputs.select-main-options {
  width: 200px;
}
.clean-input,
.clean-input.Select > .Select-control {
  box-shadow: none;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #e2e2e2;
  height: 35px;
  border-radius: 0 !important;
  margin: 0 0 64px 0;
  position: relative;
  padding: 0;
  font-size: 16px;
  z-index: 2;
  background: none;
  width: 100%;
}
.clean-input.margin-45,
.clean-input.Select > .Select-control.margin-45 {
  margin-bottom: 45px;
}
.clean-input.margin-30,
.clean-input.Select > .Select-control.margin-30 {
  margin-bottom: 30px;
}
.clean-input:disabled,
.clean-input.Select > .Select-control:disabled {
  color: #e2e2e2;
}
.clean-input + label,
.clean-input.Select > .Select-control + label {
  position: absolute;
  font-weight: normal;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 35px;
  margin-bottom: 0;
  font-size: 14px;
  color: #6e6e6e;
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
  z-index: 1;
}
.clean-input + label.input-active,
.clean-input.Select > .Select-control + label.input-active {
  color: #6e6e6e;
  font-size: 11px;
  font-weight: 500;
  top: -30px;
}
.clean-input ~ .input-right-icon,
.clean-input.Select > .Select-control ~ .input-right-icon {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 7px;
  font-size: 20px;
  color: #5c5c5c;
  border: none;
  background-color: Transparent;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
}
.clean-input ~ .input-right-icon:hover,
.clean-input.Select > .Select-control ~ .input-right-icon:hover {
  opacity: 1;
}
.clean-input.clean-text-area,
.clean-input.Select > .Select-control.clean-text-area {
  min-height: 35px !important;
  line-height: 35px;
}
.clean-input.clean-text-area + label + .help-message,
.clean-input.Select > .Select-control.clean-text-area + label + .help-message {
  top: auto;
  bottom: 25px;
}
.clean-input:focus,
.clean-input.Select > .Select-control:focus,
.clean-input > .Select-control:active,
.clean-input.Select > .Select-control > .Select-control:active {
  box-shadow: none !important;
}
.clean-input:focus + label,
.clean-input.Select > .Select-control:focus + label,
.clean-input > .Select-control:active + label,
.clean-input.Select > .Select-control > .Select-control:active + label {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
  top: -30px;
}
.clean-input:focus ~ .input-right-icon,
.clean-input.Select > .Select-control:focus ~ .input-right-icon,
.clean-input > .Select-control:active ~ .input-right-icon,
.clean-input.Select > .Select-control > .Select-control:active ~ .input-right-icon {
  opacity: 1;
}
.clean-input > option:first-child,
.clean-input.Select > .Select-control > option:first-child {
  font-size: 14px;
  color: #d5d5d5;
}
.clean-input:focus,
.clean-input.Select > .Select-control:focus,
.clean-input:active,
.clean-input.Select > .Select-control:active {
  outline: 0;
  box-shadow: 0;
}
.clean-input:focus ~ .help-message,
.clean-input.Select > .Select-control:focus ~ .help-message,
.clean-input:active ~ .help-message,
.clean-input.Select > .Select-control:active ~ .help-message {
  opacity: 1;
  height: auto;
}
.dashed-border-typeahead .clean-input.Select,
.dashed-border-typeahead .clean-input.Select > .Select-control.Select {
  border-bottom: none;
}
.dashed-border-typeahead .clean-input .Select-control,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control,
.dashed-border-typeahead .clean-input .Select-control:active,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active {
  padding: 0;
  border-width: 0 0 1px 0 !important;
  border-radius: 0;
  border-style: dashed !important;
  box-shadow: none;
  line-height: 30px;
  font-size: 18px;
}
.dashed-border-typeahead .clean-input .Select-control .Select-input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control .Select-input,
.dashed-border-typeahead .clean-input .Select-control:active .Select-input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active .Select-input,
.dashed-border-typeahead .clean-input .Select-control .Select-value-label,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control .Select-value-label,
.dashed-border-typeahead .clean-input .Select-control:active .Select-value-label,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active .Select-value-label {
  height: 30px;
  line-height: 30px;
  padding: 0;
  font-size: 18px !important;
}
.dashed-border-typeahead .clean-input .Select-control .Select-input > input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control .Select-input > input,
.dashed-border-typeahead .clean-input .Select-control:active .Select-input > input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active .Select-input > input,
.dashed-border-typeahead .clean-input .Select-control .Select-value-label > input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control .Select-value-label > input,
.dashed-border-typeahead .clean-input .Select-control:active .Select-value-label > input,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active .Select-value-label > input {
  line-height: 30px;
  padding: 0;
  height: 30px;
}
.dashed-border-typeahead .clean-input .Select-control .Select-value,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control .Select-value,
.dashed-border-typeahead .clean-input .Select-control:active .Select-value,
.dashed-border-typeahead .clean-input.Select > .Select-control .Select-control:active .Select-value {
  padding-right: 20px !important;
}
.clean-input.show-caret .bootstrap-typeahead-input-main,
.clean-input.Select > .Select-control.show-caret .bootstrap-typeahead-input-main {
  padding-right: 20px;
}
.clean-input.show-caret:before,
.clean-input.Select > .Select-control.show-caret:before {
  display: inline-block;
  content: '\e90f';
  font-family: 'bookmd_icons';
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 2;
  font-size: 10px;
}
.clean-input.Select-control,
.bootstrap-typeahead {
  z-index: 2;
}
.clean-input.Select,
.bootstrap-typeahead {
  z-index: auto;
}
.clean-input.Select.has-value > .Select-control .Select-value,
.bootstrap-typeahead.has-value > .Select-control .Select-value {
  padding-right: 40px;
  padding-left: 0;
}
.clean-input.Select.has-value > .Select-control .Select-value .Select-value-label,
.bootstrap-typeahead.has-value > .Select-control .Select-value .Select-value-label {
  color: #083a6b;
  font-size: 16px;
  display: inline-block;
  max-width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-input.Select.is-focused:not(.is-open) > .Select-control,
.bootstrap-typeahead.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}
.clean-input.Select.is-focused + label,
.bootstrap-typeahead.is-focused + label,
.clean-input.Select.is-open + label,
.bootstrap-typeahead.is-open + label {
  color: #6e6e6e;
  font-size: 11px;
  font-weight: 500;
  top: -30px;
}
.clean-input.Select .Select-value,
.bootstrap-typeahead .Select-value {
  padding-left: 0;
}
.clean-input.Select .Select-clear-zone,
.bootstrap-typeahead .Select-clear-zone {
  color: #5c5c5c;
}
.clean-input.Select.is-disabled,
.bootstrap-typeahead.is-disabled {
  min-height: 0;
  padding: 0;
  pointer-events: none;
}
.clean-input.Select.is-disabled .Select-value-label,
.bootstrap-typeahead.is-disabled .Select-value-label {
  color: #d8d8d8 !important;
}
.clean-input.Select.is-disabled .title-option,
.bootstrap-typeahead.is-disabled .title-option {
  height: 24px;
  background: #d6ebf9;
  padding: 0 15px;
  font-size: 14px;
  line-height: 24px;
  min-height: 24px;
  pointer-events: none;
}
.clean-input.Select.is-disabled > .Select-control,
.bootstrap-typeahead.is-disabled > .Select-control {
  background: none;
}
.clean-input.Select.grey-select,
.bootstrap-typeahead.grey-select {
  background: #f2f2f2;
}
.clean-input.Select.grey-select .Select-control .Select-value .Select-value-label,
.bootstrap-typeahead.grey-select .Select-control .Select-value .Select-value-label {
  font-weight: 600;
  color: #6e6e6e;
}
.clean-input.Select .Select-placeholder,
.bootstrap-typeahead .Select-placeholder {
  padding: 0;
  font-size: 16px;
}
.clean-input.Select.is-open > .Select-control,
.bootstrap-typeahead.is-open > .Select-control,
.clean-input.Select.is-focused > .Select-control,
.bootstrap-typeahead.is-focused > .Select-control {
  z-index: 3;
  border-bottom: 2px solid #083a6b;
}
.clean-input.Select.is-open > .Select-control .Select-control,
.bootstrap-typeahead.is-open > .Select-control .Select-control,
.clean-input.Select.is-focused > .Select-control .Select-control,
.bootstrap-typeahead.is-focused > .Select-control .Select-control {
  border-bottom: 2px solid #083a6b;
  box-shadow: none;
  outline: none;
}
.clean-input.Select.is-open > .Select-control .Select-input,
.bootstrap-typeahead.is-open > .Select-control .Select-input,
.clean-input.Select.is-focused > .Select-control .Select-input,
.bootstrap-typeahead.is-focused > .Select-control .Select-input {
  padding: 0;
}
.clean-input.Select.is-open > .Select-control .Select-input > input,
.bootstrap-typeahead.is-open > .Select-control .Select-input > input,
.clean-input.Select.is-focused > .Select-control .Select-input > input,
.bootstrap-typeahead.is-focused > .Select-control .Select-input > input {
  padding: 0;
  margin-top: 5px;
}
.clean-input.Select.is-open > .Select-control + label,
.bootstrap-typeahead.is-open > .Select-control + label,
.clean-input.Select.is-focused > .Select-control + label,
.bootstrap-typeahead.is-focused > .Select-control + label {
  color: #5c5c5c;
  font-size: 11px;
  font-weight: 500;
  top: -30px;
}
.clean-input.Select.is-open > .Select-control .Select-arrow,
.bootstrap-typeahead.is-open > .Select-control .Select-arrow,
.clean-input.Select.is-focused > .Select-control .Select-arrow,
.bootstrap-typeahead.is-focused > .Select-control .Select-arrow {
  border-color: transparent;
  border-width: 0 0 0 0;
  top: 0;
}
.clean-input.Select.has-value.is-pseudo-focused > .Select-control .Select-value .Select-value-label,
.bootstrap-typeahead.has-value.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
  color: #083a6b;
}
.clean-input.Select .Select-arrow,
.bootstrap-typeahead .Select-arrow {
  border-color: transparent;
  border-width: 0 0 0 0;
  cursor: pointer;
}
.clean-input.Select .Select-arrow:after,
.bootstrap-typeahead .Select-arrow:after {
  display: inline-block;
  content: '\e90f';
  font-family: 'bookmd_icons';
  font-size: 13px;
  color: #5c5c5c;
}
.clean-input.Select .Select-clear-zone .Select-clear,
.bootstrap-typeahead .Select-clear-zone .Select-clear {
  font-size: 0;
}
.clean-input.Select .Select-clear-zone .Select-clear:after,
.bootstrap-typeahead .Select-clear-zone .Select-clear:after {
  display: block;
  content: '\e60f';
  font-family: 'bookmd_icons';
  color: #5c5c5c;
  font-size: 12px;
}
.clean-input.Select .Select-menu-outer,
.bootstrap-typeahead .Select-menu-outer {
  margin-top: 10px;
  border-radius: 0 !important;
  border: none;
  z-index: 20;
}
.clean-input.Select .Select-menu,
.bootstrap-typeahead .Select-menu,
.clean-input.Select .dropdown-menu,
.bootstrap-typeahead .dropdown-menu {
  border-radius: 0 !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  max-width: 100%;
  width: 100%;
}
.clean-input.Select .Select-option,
.bootstrap-typeahead .Select-option,
.clean-input.Select .dropdown-menu > li > a,
.bootstrap-typeahead .dropdown-menu > li > a {
  font-size: 16px;
  padding: 12px 15px;
  min-height: 44px;
  line-height: 20px;
  color: #6e6e6e;
  background: #fff;
}
.clean-input.Select .Select-option .highlight,
.bootstrap-typeahead .Select-option .highlight,
.clean-input.Select .dropdown-menu > li > a .highlight,
.bootstrap-typeahead .dropdown-menu > li > a .highlight {
  background: #b5e6f7;
  font-weight: normal;
  padding: 2px 0;
}
.clean-input.Select .Select-option .profile-img-option,
.bootstrap-typeahead .Select-option .profile-img-option,
.clean-input.Select .dropdown-menu > li > a .profile-img-option,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option {
  padding: 0 0 0 50px;
  height: 36px;
  min-height: 36px;
  position: relative;
}
.clean-input.Select .Select-option .profile-img-option.hide-img,
.bootstrap-typeahead .Select-option .profile-img-option.hide-img,
.clean-input.Select .dropdown-menu > li > a .profile-img-option.hide-img,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option.hide-img {
  padding: 0;
}
.clean-input.Select .Select-option .profile-img-option .select-option-rounded-img,
.bootstrap-typeahead .Select-option .profile-img-option .select-option-rounded-img,
.clean-input.Select .dropdown-menu > li > a .profile-img-option .select-option-rounded-img,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option .select-option-rounded-img {
  position: absolute;
  top: -2px;
  left: 0px;
}
.clean-input.Select .Select-option .profile-img-option .select-option-rounded-img .content,
.bootstrap-typeahead .Select-option .profile-img-option .select-option-rounded-img .content,
.clean-input.Select .dropdown-menu > li > a .profile-img-option .select-option-rounded-img .content,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option .select-option-rounded-img .content {
  font-size: 16px;
  text-align: center;
  display: block;
}
.clean-input.Select .Select-option .profile-img-option .option-info,
.bootstrap-typeahead .Select-option .profile-img-option .option-info,
.clean-input.Select .dropdown-menu > li > a .profile-img-option .option-info,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option .option-info {
  display: block;
  line-height: 16px;
  font-size: 14px;
  color: #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-input.Select .Select-option .profile-img-option .option-name,
.bootstrap-typeahead .Select-option .profile-img-option .option-name,
.clean-input.Select .dropdown-menu > li > a .profile-img-option .option-name,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option .option-name {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #5c5c5c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-input.Select .Select-option .profile-img-option.tiny,
.bootstrap-typeahead .Select-option .profile-img-option.tiny,
.clean-input.Select .dropdown-menu > li > a .profile-img-option.tiny,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option.tiny {
  height: auto;
  min-height: 32px;
}
.clean-input.Select .Select-option .profile-img-option.tiny .option-name,
.bootstrap-typeahead .Select-option .profile-img-option.tiny .option-name,
.clean-input.Select .dropdown-menu > li > a .profile-img-option.tiny .option-name,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option.tiny .option-name {
  line-height: 32px;
}
.clean-input.Select .Select-option .profile-img-option.tiny .select-option-rounded-img,
.bootstrap-typeahead .Select-option .profile-img-option.tiny .select-option-rounded-img,
.clean-input.Select .dropdown-menu > li > a .profile-img-option.tiny .select-option-rounded-img,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option.tiny .select-option-rounded-img {
  top: 0;
}
.clean-input.Select .Select-option .profile-img-option .pic-wrap,
.bootstrap-typeahead .Select-option .profile-img-option .pic-wrap,
.clean-input.Select .dropdown-menu > li > a .profile-img-option .pic-wrap,
.bootstrap-typeahead .dropdown-menu > li > a .profile-img-option .pic-wrap {
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.clean-input.Select .Select-option .hide-img .select-option-rounded-img,
.bootstrap-typeahead .Select-option .hide-img .select-option-rounded-img,
.clean-input.Select .dropdown-menu > li > a .hide-img .select-option-rounded-img,
.bootstrap-typeahead .dropdown-menu > li > a .hide-img .select-option-rounded-img,
.clean-input.Select .Select-option .title-option .select-option-rounded-img,
.bootstrap-typeahead .Select-option .title-option .select-option-rounded-img,
.clean-input.Select .dropdown-menu > li > a .title-option .select-option-rounded-img,
.bootstrap-typeahead .dropdown-menu > li > a .title-option .select-option-rounded-img {
  padding: 0;
  display: none;
}
.clean-input.Select .Select-option:hover,
.bootstrap-typeahead .Select-option:hover,
.clean-input.Select .dropdown-menu > li > a:hover,
.bootstrap-typeahead .dropdown-menu > li > a:hover,
.clean-input.Select .Select-option:active,
.bootstrap-typeahead .Select-option:active,
.clean-input.Select .dropdown-menu > li > a:active,
.bootstrap-typeahead .dropdown-menu > li > a:active,
.clean-input.Select .Select-option.is-focused,
.bootstrap-typeahead .Select-option.is-focused,
.clean-input.Select .dropdown-menu > li > a.is-focused,
.bootstrap-typeahead .dropdown-menu > li > a.is-focused,
.clean-input.Select .Select-option:hover > a,
.bootstrap-typeahead .Select-option:hover > a,
.clean-input.Select .dropdown-menu > li > a:hover > a,
.bootstrap-typeahead .dropdown-menu > li > a:hover > a,
.clean-input.Select .Select-option:focus > a,
.bootstrap-typeahead .Select-option:focus > a,
.clean-input.Select .dropdown-menu > li > a:focus > a,
.bootstrap-typeahead .dropdown-menu > li > a:focus > a,
.clean-input.Select .Select-option:active > a,
.bootstrap-typeahead .Select-option:active > a,
.clean-input.Select .dropdown-menu > li > a:active > a,
.bootstrap-typeahead .dropdown-menu > li > a:active > a {
  color: #fff;
}
.clean-input.Select .Select-option:hover .highlight,
.bootstrap-typeahead .Select-option:hover .highlight,
.clean-input.Select .dropdown-menu > li > a:hover .highlight,
.bootstrap-typeahead .dropdown-menu > li > a:hover .highlight,
.clean-input.Select .Select-option:active .highlight,
.bootstrap-typeahead .Select-option:active .highlight,
.clean-input.Select .dropdown-menu > li > a:active .highlight,
.bootstrap-typeahead .dropdown-menu > li > a:active .highlight,
.clean-input.Select .Select-option.is-focused .highlight,
.bootstrap-typeahead .Select-option.is-focused .highlight,
.clean-input.Select .dropdown-menu > li > a.is-focused .highlight,
.bootstrap-typeahead .dropdown-menu > li > a.is-focused .highlight,
.clean-input.Select .Select-option:hover > a .highlight,
.bootstrap-typeahead .Select-option:hover > a .highlight,
.clean-input.Select .dropdown-menu > li > a:hover > a .highlight,
.bootstrap-typeahead .dropdown-menu > li > a:hover > a .highlight,
.clean-input.Select .Select-option:focus > a .highlight,
.bootstrap-typeahead .Select-option:focus > a .highlight,
.clean-input.Select .dropdown-menu > li > a:focus > a .highlight,
.bootstrap-typeahead .dropdown-menu > li > a:focus > a .highlight,
.clean-input.Select .Select-option:active > a .highlight,
.bootstrap-typeahead .Select-option:active > a .highlight,
.clean-input.Select .dropdown-menu > li > a:active > a .highlight,
.bootstrap-typeahead .dropdown-menu > li > a:active > a .highlight {
  background: rgba(181, 230, 247, 0.3);
}
.clean-input.Select .Select-option:hover .option-info,
.bootstrap-typeahead .Select-option:hover .option-info,
.clean-input.Select .dropdown-menu > li > a:hover .option-info,
.bootstrap-typeahead .dropdown-menu > li > a:hover .option-info,
.clean-input.Select .Select-option:active .option-info,
.bootstrap-typeahead .Select-option:active .option-info,
.clean-input.Select .dropdown-menu > li > a:active .option-info,
.bootstrap-typeahead .dropdown-menu > li > a:active .option-info,
.clean-input.Select .Select-option.is-focused .option-info,
.bootstrap-typeahead .Select-option.is-focused .option-info,
.clean-input.Select .dropdown-menu > li > a.is-focused .option-info,
.bootstrap-typeahead .dropdown-menu > li > a.is-focused .option-info,
.clean-input.Select .Select-option:hover > a .option-info,
.bootstrap-typeahead .Select-option:hover > a .option-info,
.clean-input.Select .dropdown-menu > li > a:hover > a .option-info,
.bootstrap-typeahead .dropdown-menu > li > a:hover > a .option-info,
.clean-input.Select .Select-option:focus > a .option-info,
.bootstrap-typeahead .Select-option:focus > a .option-info,
.clean-input.Select .dropdown-menu > li > a:focus > a .option-info,
.bootstrap-typeahead .dropdown-menu > li > a:focus > a .option-info,
.clean-input.Select .Select-option:active > a .option-info,
.bootstrap-typeahead .Select-option:active > a .option-info,
.clean-input.Select .dropdown-menu > li > a:active > a .option-info,
.bootstrap-typeahead .dropdown-menu > li > a:active > a .option-info {
  color: #fff;
}
.clean-input.Select .Select-option:hover .option-name,
.bootstrap-typeahead .Select-option:hover .option-name,
.clean-input.Select .dropdown-menu > li > a:hover .option-name,
.bootstrap-typeahead .dropdown-menu > li > a:hover .option-name,
.clean-input.Select .Select-option:active .option-name,
.bootstrap-typeahead .Select-option:active .option-name,
.clean-input.Select .dropdown-menu > li > a:active .option-name,
.bootstrap-typeahead .dropdown-menu > li > a:active .option-name,
.clean-input.Select .Select-option.is-focused .option-name,
.bootstrap-typeahead .Select-option.is-focused .option-name,
.clean-input.Select .dropdown-menu > li > a.is-focused .option-name,
.bootstrap-typeahead .dropdown-menu > li > a.is-focused .option-name,
.clean-input.Select .Select-option:hover > a .option-name,
.bootstrap-typeahead .Select-option:hover > a .option-name,
.clean-input.Select .dropdown-menu > li > a:hover > a .option-name,
.bootstrap-typeahead .dropdown-menu > li > a:hover > a .option-name,
.clean-input.Select .Select-option:focus > a .option-name,
.bootstrap-typeahead .Select-option:focus > a .option-name,
.clean-input.Select .dropdown-menu > li > a:focus > a .option-name,
.bootstrap-typeahead .dropdown-menu > li > a:focus > a .option-name,
.clean-input.Select .Select-option:active > a .option-name,
.bootstrap-typeahead .Select-option:active > a .option-name,
.clean-input.Select .dropdown-menu > li > a:active > a .option-name,
.bootstrap-typeahead .dropdown-menu > li > a:active > a .option-name {
  color: #fff;
}
@media (max-width: 768px) {
  .clean-input.Select .Select-option:hover,
  .bootstrap-typeahead .Select-option:hover,
  .clean-input.Select .dropdown-menu > li > a:hover,
  .bootstrap-typeahead .dropdown-menu > li > a:hover {
    background: none !important;
    color: #6e6e6e !important;
  }
  .clean-input.Select .Select-option:hover .highlight,
  .bootstrap-typeahead .Select-option:hover .highlight,
  .clean-input.Select .dropdown-menu > li > a:hover .highlight,
  .bootstrap-typeahead .dropdown-menu > li > a:hover .highlight {
    background: #b5e6f7 !important;
  }
  .clean-input.Select .Select-option:hover .option-info,
  .bootstrap-typeahead .Select-option:hover .option-info,
  .clean-input.Select .dropdown-menu > li > a:hover .option-info,
  .bootstrap-typeahead .dropdown-menu > li > a:hover .option-info {
    color: #6e6e6e !important;
  }
  .clean-input.Select .Select-option:hover .option-name,
  .bootstrap-typeahead .Select-option:hover .option-name,
  .clean-input.Select .dropdown-menu > li > a:hover .option-name,
  .bootstrap-typeahead .dropdown-menu > li > a:hover .option-name {
    color: #6e6e6e !important;
  }
}
.clean-input.Select .dropdown-menu > li.no-results-select-option a,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a {
  pointer-events: none;
  white-space: normal;
  text-align: center;
  font-size: 18px;
  padding: 60px 50px;
  font-weight: 100;
}
.clean-input.Select .dropdown-menu > li.no-results-select-option a img,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a img,
.clean-input.Select .dropdown-menu > li.no-results-select-option a .svg-icon,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a .svg-icon {
  width: 72px;
  height: auto;
  margin: 0 0 20px 0;
}
.clean-input.Select .dropdown-menu > li.no-results-select-option a:hover,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a:hover,
.clean-input.Select .dropdown-menu > li.no-results-select-option a:focus,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a:focus,
.clean-input.Select .dropdown-menu > li.no-results-select-option a:hover > a,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a:hover > a,
.clean-input.Select .dropdown-menu > li.no-results-select-option a:focus > a,
.bootstrap-typeahead .dropdown-menu > li.no-results-select-option a:focus > a {
  background: #fff;
  color: #6e6e6e;
}
.left-label-icon .clean-input.Select .Select-placeholder,
.left-label-icon .bootstrap-typeahead .Select-placeholder,
.left-label-icon .clean-input.Select .Select-input,
.left-label-icon .bootstrap-typeahead .Select-input,
.left-label-icon .clean-input.Select .Select-value,
.left-label-icon .bootstrap-typeahead .Select-value {
  padding-left: 30px;
}
.left-label-icon .clean-input.Select.has-value > .Select-control .Select-value,
.left-label-icon .bootstrap-typeahead.has-value > .Select-control .Select-value {
  padding-left: 30px;
}
.clean-input.Select .bootstrap-typeahead-input-main.form-control,
.bootstrap-typeahead .bootstrap-typeahead-input-main.form-control {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 16px;
}
.clean-input.Select .bootstrap-typeahead-input-main.form-control::placeholder,
.bootstrap-typeahead .bootstrap-typeahead-input-main.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #737373;
  opacity: 1;
  /* Firefox */
}
.clean-input.Select .bootstrap-typeahead-input-main.form-control:-ms-input-placeholder,
.bootstrap-typeahead .bootstrap-typeahead-input-main.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #737373;
}
.clean-input.Select .bootstrap-typeahead-input-main.form-control::-ms-input-placeholder,
.bootstrap-typeahead .bootstrap-typeahead-input-main.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #737373;
}
@media (min-width: 768px) {
  .clean-input.Select .bootstrap-typeahead-input-main.form-control,
  .bootstrap-typeahead .bootstrap-typeahead-input-main.form-control {
    border-radius: 5px;
  }
}
.clean-input.Select .spinner,
.bootstrap-typeahead .spinner {
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 2px 0 0 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 10px 0 4px 0;
  position: relative;
  z-index: 20;
}
.clean-input.Select .spinner .svg-loader,
.bootstrap-typeahead .spinner .svg-loader {
  display: inline-block;
}
.select-left-icon {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 19px;
  color: #6e6e6e;
}
label.static-label-header {
  line-height: 35px;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: 500;
}
.form-group {
  position: relative;
  margin-bottom: 0;
}
.checkbox-position {
  padding-top: 7px;
  min-width: 56px;
}
.date-component .error-wrap {
  position: relative;
}
.date-component .past-date-message.validation-message {
  position: relative;
  bottom: 35px;
  display: inline-block;
  top: auto;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.validation-error,
.has-error {
  color: #b12704;
}
.validation-error .clean-input,
.has-error .clean-input,
.validation-error .clean-select + .select2-container,
.has-error .clean-select + .select2-container {
  border-bottom: 1px solid #b12704;
}
.validation-error .validation-message,
.has-error .validation-message {
  display: block;
}
.validation-error.warning,
.has-error.warning {
  color: #6e6e6e;
}
.validation-error.warning .clean-input,
.has-error.warning .clean-input,
.validation-error.warning .clean-select + .select2-container,
.has-error.warning .clean-select + .select2-container,
.validation-error.warning .clean-input.Select > .Select-control,
.has-error.warning .clean-input.Select > .Select-control {
  border-bottom: 1px solid #f89e06;
}
.validation-error.top .validation-message,
.has-error.top .validation-message {
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  top: -35px;
}
.validation-error.top .validation-message:after,
.has-error.top .validation-message:after {
  border-width: 5px 5px 0 5px;
  border-color: #b12704 transparent transparent transparent;
  top: auto;
  bottom: -5px;
}
.validation-message {
  display: none;
  font-size: 14px;
  position: absolute;
  top: 42px;
  color: #fff;
  background: #b12704;
  line-height: 13px;
  width: 100%;
  padding: 7px;
  z-index: 10;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
}
.validation-message:after {
  display: block;
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #b12704 transparent;
}
.validation-message:before {
  content: 'Error: ';
  display: inline;
}
.warning .validation-message {
  background: #f89e06;
}
.warning .validation-message:after {
  border-color: transparent transparent #f89e06 transparent;
}
.top .warning .validation-message:after {
  border-color: #f89e06 transparent transparent transparent;
}
.warning.top .validation-message:after {
  border-color: #f89e06 transparent transparent transparent;
}
.textarea-limit {
  top: -40px;
  position: relative;
}
.validationField-height {
  height: 80px;
}
.help-message {
  opacity: 0;
  display: block;
  position: absolute;
  top: 45px;
  left: 0;
  font-size: 14px;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.relative-help-text .help-message {
  position: relative;
  top: 5px;
}
.clean-typeahead {
  position: relative;
}
input.clean-input::-ms-clear {
  display: none;
}
input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::-moz-placeholder {
  font-size: 16px;
}
table.provider-block-table {
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid #e2e2e2;
  table-layout: fixed;
}
table.provider-block-table thead tr {
  background: #fff;
  border: 1px solid #d8d8d8;
}
table.provider-block-table thead tr th {
  height: 57px;
  padding: 0 0 0 20px;
  cursor: pointer;
  font-size: 14px;
  color: #6e6e6e;
  font-weight: normal;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table.provider-block-table thead tr th .sort-icon {
  color: #6e6e6e;
  font-size: 13px;
  display: none;
}
table.provider-block-table thead tr th.sort-up {
  color: #5c5c5c;
}
table.provider-block-table thead tr th.sort-up .sort-icon {
  display: inline-block;
  transform: rotate(90deg);
}
table.provider-block-table thead tr th.sort-down {
  color: #5c5c5c;
}
table.provider-block-table thead tr th.sort-down .sort-icon {
  display: inline-block;
  transform: rotate(-90deg);
}
table.provider-block-table tbody tr td {
  height: 48px;
  font-size: 15px;
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 0 20px;
  color: #5c5c5c;
  cursor: pointer;
}
table.provider-block-table tbody tr td .delete-row-icon {
  font-size: 18px;
  color: #5c5c5c;
  padding: 0 20px 0 0;
}
table.provider-block-table tbody tr td .clean-input,
table.provider-block-table tbody tr td .clean-select {
  margin-bottom: 0;
}
table.provider-block-table tbody tr td .delete-btn {
  font-size: 21px;
  color: #5c5c5c;
  vertical-align: -4px;
}
table.provider-block-table tbody tr.pending-row:hover td {
  height: 100px;
  padding-top: 10px;
  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 100ms ease;
  -o-transition: all 100ms ease;
}
table.provider-block-table tbody tr td.footer-container {
  padding: 15px 20px;
  height: auto !important;
  cursor: default;
}
table.provider-block-table tbody tr td.footer-container:hover {
  background: #fff;
}
table.provider-block-table.appointments {
  margin: 0 0 40px 0;
}
table.provider-block-table.appointments tbody tr td {
  padding: 25px 0 25px 20px;
}
table.provider-block-table.appointments tbody tr:hover {
  background: #f2f2f2;
}
table.provider-block-table.grey-thead thead tr {
  background: #f5f5f5;
}
table.provider-block-table.grey-thead thead tr th {
  box-shadow: none;
}
table.provider-block-table.grey-thead thead tr th.delete-column {
  width: 70px;
}
table.provider-block-table.grey-thead tbody tr td {
  height: 60px;
}
table.provider-block-table.grey-thead tbody tr:hover {
  background: #f2f2f2;
}
table.provider-block-table.grey-thead tbody tr.disabled {
  background: #f2f2f2;
  cursor: default;
}
table.simple-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}
table.simple-table tr td {
  border: 1px solid #d8d8d8;
  padding: 15px 20px;
  vertical-align: top;
  width: 33.33%;
}
table.simple-table tr td.align-middle {
  vertical-align: middle;
}
.clean-table {
  display: table;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.clean-table .cell-wrap {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 56px;
}
.clean-table .cell-wrap .btn {
  display: inline-block;
}
.clean-table thead tr {
  background: #083a6b;
}
.clean-table thead tr th {
  line-height: 56px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 0 20px;
}
.clean-table tbody tr {
  background: #fff;
}
.clean-table tbody tr td {
  line-height: 56px;
  font-size: 14px;
  padding: 0 20px;
  color: #545454;
  border-bottom: 1px solid #e2e2e2;
}
.clean-table tbody tr td .cell-wrap {
  display: inherit;
  line-height: 20px;
}
.clean-table tbody tr.clickable {
  cursor: pointer;
}
.clean-table tbody tr.highlighted {
  background: #f4f4f4;
}
.clean-table tbody tr.highlighted.now {
  border-bottom: 2px solid #09ace4;
}
@media (max-width: 768px) {
  .clean-table.responsive-table {
    box-shadow: none;
    table-layout: fixed;
  }
  .clean-table.responsive-table caption {
    font-size: 1.3em;
  }
  .clean-table.responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .clean-table.responsive-table tbody tr {
    display: block;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .clean-table.responsive-table tbody tr td {
    display: block;
    padding: 0 15px 0 135px !important;
  }
  .clean-table.responsive-table tbody tr td .cell-wrap {
    line-height: 56px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .clean-table.responsive-table tbody tr td .dropdown.more-menu {
    margin: 10px 0 0 0;
  }
  .clean-table.responsive-table tbody tr td:before {
    content: attr(data-label);
    float: left;
    margin: 0 0 0 -135px;
    font-weight: 600;
    line-height: 56px;
    background: #083a6b;
    color: #fff;
    padding: 0 20px;
    width: 120px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.tooltip-icon {
  cursor: pointer;
  color: #5c5c5c;
  font-size: 20px;
  padding: 10px;
  position: relative;
  box-shadow: 0 0 0 0px #eaeaea inset;
  border-radius: 50px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.tooltip-icon:hover,
.tooltip-icon:active,
.tooltip-icon.active {
  box-shadow: 0 0 0 50px #eaeaea inset;
}
.tooltip-icon.align-fix {
  padding: 10px 8px 10px 12px;
}
.tooltip-wrap {
  display: inline-block;
}
.tooltip {
  z-index: 16000004;
}
.tooltip .tooltip-arrow {
  display: block;
}
.tooltip .tooltip-inner {
  background: #5c5c5c;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'proximaNova', Arial, Helvetica, sans-serif;
  padding: 6px 10px 4px 10px;
  border-radius: 4px !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  opacity: 1;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #5c5c5c;
}
ul.right-icons-details-list {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}
ul.right-icons-details-list li {
  position: relative;
  display: block;
  padding: 0 40px 0 0;
  margin: 0 0 30px 0;
}
ul.right-icons-details-list li i.detail-icon {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  color: #545454;
}
.CircularProgressbar {
  width: 100%;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #e2e2e2;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #09ace4;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #09ace4;
  stroke-linecap: round;
  transition: stroke-dashoffset 2s ease 0s;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #09ace4;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.CircularProgressbar.hide-Percentage .CircularProgressbar-text {
  display: none;
}
.CircularProgressbar.trans-bg .CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0.1);
}
.CircularProgressbar.white-path .CircularProgressbar-path {
  stroke: #fff;
}
.CircularProgressbar.light-blue-path .CircularProgressbar-path {
  stroke: #09ace4;
}
.banner.top {
  margin: 0 -10px 10px -10px;
  padding: 30px 30px 20px 30px;
  color: #fff;
  text-align: center;
  position: relative;
}
.banner.top .close-banner-btn {
  position: absolute;
  right: 18px;
  top: 10px;
  color: #fff;
}
.banner.top .close-banner-btn:hover,
.banner.top .close-banner-btn:focus,
.banner.top .close-banner-btn:active {
  color: #fff;
}
.banner.top .banner-right-btn {
  margin: 20px 0 0 0;
}
@media (min-width: 768px) {
  .banner.top .banner-right-btn {
    margin: 7px 0 0 0;
    float: right;
  }
}
.banner.top .banner-icon,
.banner.top .svg-icon {
  font-size: 18px;
  display: inline-block;
  margin: 0 5px 0 0;
}
.banner.top .banner-icon .fill-color-brand-main,
.banner.top .svg-icon .fill-color-brand-main,
.banner.top .banner-icon .fill-color-brand-secondary,
.banner.top .svg-icon .fill-color-brand-secondary {
  fill: #fff;
}
@media (min-width: 768px) {
  .banner.top .banner-icon,
  .banner.top .svg-icon {
    font-size: 36px;
    margin: 2px 0 0 -58px;
    position: absolute;
  }
}
.banner.top .svg-icon {
  width: 36px;
  height: auto;
  margin-top: 7px;
}
@media (max-width: 767px) {
  .banner.top .svg-icon {
    width: 18px;
    margin: 0 10px -2px 0;
  }
}
@media (min-width: 768px) {
  .banner.top {
    padding: 20px 50px 20px 85px;
    text-align: left;
  }
}
.notification.notification-error:before {
  content: 'Error:';
  display: block;
  font-weight: bold;
  color: #b12704;
}
.notification-title {
  color: #000000 !important;
}
.bootstrap-typeahead.dashed-border-typeahead .bootstrap-typeahead-input-main {
  padding: 0;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-style: dashed;
  box-shadow: none;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
}
.bootstrap-typeahead.show-caret .bootstrap-typeahead-input-main {
  padding-right: 20px;
}
.bootstrap-typeahead.show-caret:before {
  display: inline-block;
  content: '\e90f';
  font-family: 'bookmd_icons';
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 2;
  font-size: 10px;
}
body *:focus {
  outline: none;
}
body.accessibility-tab-focus *:focus {
  outline: #6e6e6e 2px dotted !important;
  outline-offset: 3px;
}
body.accessibility-tab-focus *.white-focus:focus {
  outline: #fff 2px dotted !important;
}
