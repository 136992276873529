@import '~@getvim/components-base-css/src/bookmd-import';

ul.right-icons-details-list {
  margin: 30px 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    display: block;
    padding: 0 40px 0 0;
    margin: 0 0 30px 0;

    i.detail-icon {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 18px;
      color: @case-past-emergency;
    }
  }
}
