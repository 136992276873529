.something-went-wrong-error {
  --main-color: #045393;
  --secondary-color: #48c85a;
  --secondary-dark-color: #32333c;
  --secondary-color-light: #e1eaf1;
  --secondary-color-lighter: #f2f2f9;
  --button-color: #045393;
  --accent-color: #fa7071;
  --link-color: #045393;
  --success-color: #48c85a;
}
