@import '~@getvim/components-base-css/src/bookmd-import.less';

.filters-widget {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 2px 40px;
  position: sticky;
  top: 0;
  z-index: 2;

  & > .filter-item {
    flex: 0 0 auto;
    margin: 0 30px 0 0;
    .transition(all 200ms easeOut);

    & > .btnx {
      text-align: left;
      .three-dots();

      &.distance-btn {
        width: 78px;
      }

      &.language-btn {
        width: 82px;
      }

      &.gender-btn {
        width: 72px;
      }

      &.filters-btn {
        width: 80px;
      }

      &:focus {
        outline-color: #fff !important;
        outline-offset: 0px;
      }
    }
  }

  input[type='checkbox']:focus {
    outline-color: #fff !important;
  }

  .clear-filter-btn {
    line-height: 36px;
    font-size: 14px;
    font-weight: @medium;

    i {
      font-size: 10px;
      display: inline-block;
      margin: 0 10px 0 0;
      font-weight: @light;
    }

    .btnx:focus {
      outline-color: #fff !important;
    }
  }

  @media (max-width: @screen-sm) {
    padding: 2px 30px;
  }
}
