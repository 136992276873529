@rounded-img-default-size: 50px;

.three-dots() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-long-words() {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.vertical-align-middle (@position: relative) {
  position: @position;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.horizontal-align-middle() {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.xy-align-middle() {
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}

.img-cover() {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //.vertical-align-middle;
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
}

.transform(@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
}

.img-half-size() {
  -webkit-transform: scale(0.5); /* Saf3.1+, Chrome */
  -moz-transform: scale(0.5); /* FF3.5+ */
  -ms-transform: scale(0.5); /* IE9 */
  -o-transform: scale(0.5); /* Opera 10.5+ */
  transform: scale(0.5);
  transform-origin: top left;
  -webkit-transform-origin: top left; /* Saf3.1+, Chrome */
  -moz-transform-origin: top left; /* FF3.5+ */
  -ms-transform-origin: top left; /* IE9 */
  -o-transform-origin: top left; /* Opera 10.5+ */
  /* IE6–IE9 */
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
}

.img-half-size-centered() {
  -webkit-transform: scale(0.5) translateX(-50%); /* Saf3.1+, Chrome */
  -moz-transform: scale(0.5) translateX(-50%); /* FF3.5+ */
  -ms-transform: scale(0.5) translateX(-50%); /* IE9 */
  -o-transform: scale(0.5) translateX(-50%); /* Opera 10.5+ */
  transform: scale(0.5) translateX(-50%);
  transform-origin: top left;
  -webkit-transform-origin: top left; /* Saf3.1+, Chrome */
  -moz-transform-origin: top left; /* FF3.5+ */
  -ms-transform-origin: top left; /* IE9 */
  -o-transform-origin: top left; /* Opera 10.5+ */
  left: 50%;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }
}

.rounded-img-mixin(@size: @rounded-img-default-size; @border-width: 5px; @border-color: #fff; @font-size: 40px) {
  width: @size;
  height: @size;
  border-width: @border-width;
  border-color: @border-color;
  border-radius: 100%;
  border-style: solid;
  overflow: hidden;

  img {
    .img-cover();
  }

  .content {
    font-size: @font-size;
    font-weight: bold;
    color: @bookmd-text-color;
    line-height: @size;
  }
}

.btn-outline-pill-mixin(@color: @bookmd-text-color) {
  border-color: @color;
  color: @color;
  box-shadow: 0 0 0 @color;

  &:hover {
    background: @color;
    color: #fff;
  }
}

.btn-outline-pill-selected-mixin(@color: @bookmd-text-color; @border-space-color: @bookmd-background-color-1) {
  box-shadow: 0 0 0 4px @border-space-color, 0 0 0 5px @color;
  background: @color;
  color: #fff;
}
