@import '~@getvim/components-base-css/src/bookmd-import';

//Block table
table.provider-block-table {
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid @bookmd-border-color;
  table-layout: fixed;

  thead {
    tr {
      background: #fff;
      border: 1px solid @bookmd-providers-light-grey;

      th {
        height: 57px;
        padding: 0 0 0 20px;
        cursor: pointer;
        font-size: 14px;
        color: @bookmd-text-color;
        font-weight: normal;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .sort-icon {
          color: @bookmd-text-color;
          font-size: 13px;
          display: none;
          //margin-left: -17px;
        }

        &.sort-up {
          color: @bookmd-text-dark-color;

          .sort-icon {
            display: inline-block;
            transform: rotate(90deg);
          }
        }

        &.sort-down {
          color: @bookmd-text-dark-color;

          .sort-icon {
            display: inline-block;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        height: 48px;
        font-size: 15px;
        border-bottom: 1px solid @bookmd-border-color;
        padding: 0 0 0 20px;
        color: @bookmd-text-dark-color;
        cursor: pointer;

        .delete-row-icon {
          font-size: 18px;
          color: @bookmd-text-dark-color;
          padding: 0 20px 0 0;
        }

        .clean-input,
        .clean-select {
          margin-bottom: 0;
        }

        .delete-btn {
          font-size: 21px;
          color: @bookmd-text-dark-color;
          vertical-align: -4px;
        }
      }

      &.pending-row {
        &:hover {
          td {
            height: 100px;
            padding-top: 10px;
            .transition(all 100ms ease);
          }
        }
      }
    }
  }

  tbody tr td.footer-container {
    padding: 15px 20px;
    height: auto !important;
    cursor: default;

    &:hover {
      background: #fff;
    }
  }

  &.appointments {
    margin: 0 0 40px 0;

    tbody {
      tr {
        td {
          padding: 25px 0 25px 20px;
        }

        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }

  &.grey-thead {
    thead {
      tr {
        background: @bookmd-rp-light-grey-color;

        th {
          box-shadow: none;

          &.delete-column {
            width: 70px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          height: 60px;
        }

        &:hover {
          background: #f2f2f2;
        }

        &.disabled {
          background: #f2f2f2;
          cursor: default;
        }
      }
    }
  }
}

table.simple-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  tr {
    td {
      border: 1px solid @bookmd-providers-light-grey;
      padding: 15px 20px;
      vertical-align: top;
      width: 33.33%;

      &.align-middle {
        vertical-align: middle;
      }
    }
  }
}
