@import '~@getvim/components-base-css/src/bookmd-import.less';

.filters-modal {
  display: flex;
  flex-direction: column;

  .dialog-body {
    padding-top: 0;
  }

  .left-action-btn {
    margin-right: 20px !important;
  }

  .filter-section {
    position: relative;
  }

  button.btnx {
    &.btn-outline {
      position: relative;
      text-align: left;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        background: @bookmd-light-grey-color-f4;
      }
    }
  }

  .language-filter-popover.reakit-popover,
  .gender-filter-popover.reakit-popover {
    width: 100%;
    position: absolute;
    z-index: 1;
    background: #fff;

    .popover-content {
      padding: 0;
    }
  }

  .language-btn,
  .gender-btn {
    &:before {
      content: '\e98b';
      font-family: 'bookmd_icons';
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 9px;
      display: inline-block;
      transform: rotate(180deg);
    }
  }
}
