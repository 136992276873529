@import '~@getvim/components-base-css/src/bookmd-import';

.clean-table {
  display: table;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  .cell-wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    line-height: 56px;

    .btn {
      display: inline-block;
    }
  }

  thead {
    tr {
      background: @bookmd-main-color;

      th {
        line-height: 56px;
        font-size: 16px;
        font-weight: @semibold;
        color: #fff;
        padding: 0 20px;
      }
    }
  }

  tbody {
    tr {
      background: #fff;

      td {
        line-height: 56px;
        font-size: 14px;
        padding: 0 20px;
        color: @case-past-emergency;
        border-bottom: 1px solid @bookmd-border-color;

        .cell-wrap {
          //display: block;
          //width: 100%;
          //height: 100%;
          //position: relative;
          display: inherit;
          line-height: 20px;

          &:after {
          }
        }
      }

      &.clickable {
        cursor: pointer;
      }

      &.highlighted {
        background: @bookmd-light-grey-color-f4;

        &.now {
          border-bottom: 2px solid @bookmd-light-blue-color;

          //& > td:last-child .cell-wrap {
          //  &:after {
          //    content: '';
          //    position: absolute;
          //    right: -30px;
          //    top: 100%;
          //    margin-top: -5px;
          //    width: 0;
          //    height: 0;
          //    border-style: solid;
          //    border-width: 6px 6px 6px 0;
          //    border-color: transparent @bookmd-light-blue-color transparent transparent;
          //  }
          //}
          //
          //& > td:first-child .cell-wrap {
          //  &:after {
          //    content: '';
          //    position: absolute;
          //    left: -30px;
          //    top: 100%;
          //    margin-top: -5px;
          //    width: 0;
          //    height: 0;
          //    border-style: solid;
          //    border-width: 6px 0 6px 6px;
          //    border-color: transparent transparent transparent @bookmd-light-blue-color;
          //  }
          //}
        }
      }
    }
  }

  &.responsive-table {
    @media (max-width: @screen-sm) {
      box-shadow: none;
      table-layout: fixed;

      caption {
        font-size: 1.3em;
      }

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        tr {
          display: block;
          margin-bottom: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

          td {
            display: block;
            padding: 0 15px 0 135px !important;

            .cell-wrap {
              line-height: 56px !important;
              .three-dots();
            }

            .dropdown.more-menu {
              margin: 10px 0 0 0;
            }

            &:before {
              content: attr(data-label);
              float: left;
              margin: 0 0 0 -135px;
              font-weight: @semibold;
              line-height: 56px;
              background: @bookmd-main-color;
              color: #fff;
              padding: 0 20px;
              width: 120px;
              text-align: left;
              .three-dots();
            }
          }
        }
      }
    }
  }
}
