.pagination {
  margin: 0;

  &.pagination-lg {
    & > li {
      a, div {
        padding: 0;
        height: 48px;
        width: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        font-weight: @semibold;
        color: @bookmd-text-dark-color-2;
        border: 1px solid @bookmd-border-color;
        display: inline-block;
        background-color: white;
      }

      &.active {
        div,
        & > span {
          background: var(--main-color);
          border-color: var(--main-color);
          color: #fff;
          font-size: 16px;
          display: inline-block;
          height: 48px;
          width: 48px;
          padding: 0;
          line-height: 48px;
          text-align: center;
        }
      }

      &.disabled {
        a,
        div {
          opacity: 0.5;
        }
      }

      &:first-child {
        a,
        div {
          font-size: 0;

          &:before {
            display: inline-block;
            content: '\e90e';
            font-family: 'bookmd_icons';
            font-size: 10px;
            color: @bookmd-text-dark-color-2;
          }
        }
      }

      &:last-child {
        a,
        div {
          font-size: 0;

          &:before {
            display: inline-block;
            content: '\e90d';
            font-family: 'bookmd_icons';
            font-size: 10px;
            color: @bookmd-text-dark-color-2;
          }
        }
      }
    }

    &.pagination-vim {
      li > a:first-child,
      li > div:first-child,
      li > a:last-child,
      li > div:last-child {
        font-size: 16px;

        &:before {
          content: '';
        }
      }
    }
  }
}

.pagination.previous > li > div,
.pagination.next > li > div {
  padding: 0;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: @semibold;
  color: @bookmd-text-dark-color;
  border: 1px solid @bookmd-border-color;
  display: inline-block;
  background-color: white;
}

.pagination.previous > li > div {
  border-radius: 6px 0 0 6px;
}
.pagination.next > li > div {
  border-radius: 0 6px 6px 0;
}
