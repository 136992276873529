@font-face {
  font-family: 'bookmd_icons';
  src: url('~@getvim/components-base-css/src/fonts/bookmd_icons.eot?b5o9ll');
  src: url('~@getvim/components-base-css/src/fonts/bookmd_icons.eot?#iefix-b5o9ll') format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/bookmd_icons.ttf?b5o9ll') format('truetype'),
    url('~@getvim/components-base-css/src/fonts/bookmd_icons.woff?b5o9ll') format('woff'),
    url('~@getvim/components-base-css/src/fonts/bookmd_icons.svg?b5o9ll#bookmd_icons') format('svg');

  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'bookmd_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-doctor-2:before {
  content: "\e991";
}
.icon-award-2:before {
  content: "\e992";
}
.icon-condition-heart-2:before {
  content: "\e993";
}
.icon-syringe-2:before {
  content: "\e994";
}
.icon-gender-2:before {
  content: "\e995";
}
.icon-bell:before {
  content: "\e996";
}
.icon-paper-airplane:before {
  content: "\e997";
}
.icon-age-2:before {
  content: "\e998";
}
.icon-award:before {
  content: "\e999";
}
.icon-door-f:before {
  content: "\e99a";
}
.icon-lightning-e:before {
  content: "\e99b";
}
.icon-door:before {
  content: "\e98c";
}
.icon-hourglass-middle:before {
  content: "\e98d";
}
.icon-hourglass-bottom:before {
  content: "\e98e";
}
.icon-hourglass-top:before {
  content: "\e98f";
}
.icon-user-doctor:before {
  content: "\e990";
}
.icon-video-cam:before {
  content: "\e988";
}
.icon-envelope-c:before {
  content: "\e989";
}
.icon-calendar-check-2:before {
  content: "\e98a";
}
.icon-chevron-2:before {
  content: "\e98b";
}
.icon-clipboard:before {
  content: '\e982';
}
.icon-syringe:before {
  content: '\e983';
}
.icon-age:before {
  content: '\e984';
}
.icon-condition-heart:before {
  content: '\e985';
}
.icon-gender:before {
  content: '\e986';
}
.icon-stethoscope:before {
  content: '\e987';
}
.icon-send-arrow:before {
  content: '\e981';
}
.icon-message-b:before {
  content: '\e97e';
}
.icon-map-pin-v:before {
  content: '\e97f';
}
.icon-map-pin-x:before {
  content: '\e980';
}
.icon-team:before {
  content: '\e973';
}
.icon-dislike:before {
  content: '\e974';
}
.icon-like:before {
  content: '\e975';
}
.icon-user-re:before {
  content: '\e976';
}
.icon-ext-arrow:before {
  content: '\e977';
}
.icon-clinic-2:before {
  content: '\e978';
}
.icon-import-c:before {
  content: '\e972';
}
.icon-calendar-graph:before {
  content: '\e970';
}
.icon-graphs:before {
  content: '\e971';
}
.icon-user-c:before {
  content: '\e96e';
}
.icon-calendar-check:before {
  content: '\e96f';
}
.icon-eye-2:before {
  content: '\e96c';
}
.icon-export:before {
  content: '\e96d';
}
.icon-calc:before {
  content: '\e96b';
  color: #545454;
}
.icon-shield-q:before {
  content: '\e96a';
}
.icon-ban:before {
  content: '\e969';
}
.icon-exclamation:before {
  content: '\e968';
}
.icon-medical-team:before {
  content: '\e967';
}
.icon-refresh:before {
  content: '\e966';
}
.icon-flag:before {
  content: '\e964';
}
.icon-user-id:before {
  content: '\e965';
}
.icon-play:before {
  content: '\e963';
}
.icon-check-in:before {
  content: '\e962';
}
.icon-user-r-stars:before {
  content: '\e960';
}
.icon-calendar-stars:before {
  content: '\e95f';
}
.icon-star-sh:before {
  content: '\e95e';
}
.icon-star-up:before {
  content: '\e961';
}
.icon-dots:before {
  content: '\e95d';
  color: #545454;
}
.icon-clock:before {
  content: '\e95c';
}
.icon-lightning:before {
  content: '\e95b';
}
.icon-warning:before {
  content: '\e957';
}
.icon-check-s-2:before {
  content: '\e958';
}
.icon-user-r:before {
  content: '\e959';
}
.icon-note-h-e:before {
  content: '\e95a';
}
.icon-home-3:before {
  content: '\e953';
}
.icon-places:before {
  content: '\e954';
}
.icon-help-w:before {
  content: '\e955';
}
.icon-heart-vim-e:before {
  content: '\e956';
}
.icon-heart-vim:before {
  content: '\e952';
}
.icon-globe:before {
  content: '\e951';
}
.icon-add-member:before {
  content: '\e950';
}
.icon-notification-s:before {
  content: '\e94f';
}
.icon-calendar-sm:before {
  content: '\e94d';
}
.icon-building:before {
  content: '\e94c';
}
.icon-user-2:before {
  content: '\e94b';
}
.icon-medication:before {
  content: '\e94a';
}
.icon-browser:before {
  content: '\e948';
}
.icon-iphone:before {
  content: '\e949';
}
.icon-heart-key:before {
  content: '\e93f';
}
.icon-cog-s-2:before {
  content: '\e940';
}
.icon-md-circle:before {
  content: '\e941';
}
.icon-speak-heart:before {
  content: '\e942';
}
.icon-clinic:before {
  content: '\e943';
}
.icon-hospital-2:before {
  content: '\e944';
}
.icon-pill:before {
  content: '\e945';
}
.icon-tube:before {
  content: '\e946';
}
.icon-radiology:before {
  content: '\e947';
}
.icon-shield-check:before {
  content: '\e93e';
}
.icon-heart-e:before {
  content: '\e93a';
}
.icon-heart-f:before {
  content: '\e93b';
}
.icon-check-circle-e:before {
  content: '\e93c';
}
.icon-check-circle-f:before {
  content: '\e93d';
}
.icon-help-c-2:before {
  content: '\e937';
}
.icon-cog-s:before {
  content: '\e938';
}
.icon-search-2:before {
  content: '\e939';
}
.icon-link:before {
  content: '\e934';
}
.icon-envelope-b:before {
  content: '\e935';
}
.icon-speak-b:before {
  content: '\e936';
}
.icon-eye:before {
  content: '\e933';
}
.icon-back-arrow:before {
  content: '\e932';
}
.icon-menu-bars:before {
  content: '\e929';
}
.icon-asterisk:before {
  content: '\e92a';
}
.icon-phone-f:before {
  content: '\e92b';
}
.icon-ext-link:before {
  content: '\e92c';
}
.icon-shield:before {
  content: '\e92d';
}
.icon-map-pin-e:before {
  content: '\e92e';
}
.icon-home-2:before {
  content: '\e92f';
}
.icon-suitcase:before {
  content: '\e930';
}
.icon-location-cross:before {
  content: '\e931';
}
.icon-ul:before {
  content: '\e927';
}
.icon-cog:before {
  content: '\e928';
}
.icon-calendar-o:before {
  content: '\e91e';
}
.icon-note-h:before {
  content: '\e91f';
}
.icon-check-s:before {
  content: '\e920';
}
.icon-pie-chart:before {
  content: '\e921';
}
.icon-quote:before {
  content: '\e922';
}
.icon-user:before {
  content: '\e923';
}
.icon-plus-s:before {
  content: '\e924';
}
.icon-notebook:before {
  content: '\e925';
}
.icon-arrow-s:before {
  content: '\e926';
}
.icon-filter-sliders:before {
  content: '\e91d';
}
.icon-arrow-best:before {
  content: '\e91c';
}
.icon-chat-f:before {
  content: '\e91a';
}
.icon-chat:before {
  content: '\e91b';
}
.icon-help-center:before {
  content: '\e919';
}
.icon-filter:before {
  content: '\e916';
}
.icon-left-arrow-w:before {
  content: '\e917';
}
.icon-right-arrow-w:before {
  content: '\e918';
}
.icon-envelope:before {
  content: '\e800';
}
.icon-search:before {
  content: '\e60b';
}
.icon-notification:before {
  content: '\e60c';
}
.icon-help:before {
  content: '\e600';
}
.icon-home:before {
  content: '\e910';
}
.icon-attachment:before {
  content: '\e911';
}
.icon-print:before {
  content: '\e912';
}
.icon-hospital:before {
  content: '\e913';
}
.icon-md-bag:before {
  content: '\e914';
}
.icon-face:before {
  content: '\e915';
}
.icon-life-ring:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e90a';
}
.icon-folder:before {
  content: '\e90b';
}
.icon-chevron-up:before {
  content: '\e90c';
}
.icon-chevron-right:before {
  content: '\e90d';
}
.icon-chevron-left:before {
  content: '\e90e';
}
.icon-chevron-down:before {
  content: '\e90f';
}
.icon-map-pin:before {
  content: '\e908';
}
.icon-facility:before {
  content: '\e905';
}
.icon-hat:before {
  content: '\e906';
}
.icon-staff:before {
  content: '\e907';
}
.icon-datepicker-s:before {
  content: '\e904';
}
.icon-help-c:before {
  content: '\e903';
}
.icon-download:before {
  content: '\e902';
}
.icon-plus-fat:before {
  content: '\e901';
}
.icon-pencil:before {
  content: '\e900';
}
.icon-plus:before {
  content: '\e616';
}
.icon-info-f:before {
  content: '\e615';
}
.icon-info:before {
  content: '\e614';
}
.icon-date-picker:before {
  content: '\e613';
}
.icon-fax:before {
  content: '\e612';
}
.icon-trash:before {
  content: '\e611';
}
.icon-calendar-fav:before {
  content: '\e60d';
}
.icon-dropdown-arrow:before {
  content: '\e60e';
}
.icon-x-2:before {
  content: '\e60f';
}
.icon-search-circle:before {
  content: '\e610';
}
.icon-speak:before {
  content: '\e607';
}
.icon-phone:before {
  content: '\e608';
}
.icon-left-arrow:before {
  content: '\e609';
}
.icon-x:before {
  content: '\e60a';
}
.icon-night:before {
  content: '\e601';
}
.icon-evening:before {
  content: '\e602';
}
.icon-afternoon:before {
  content: '\e603';
}
.icon-morning:before {
  content: '\e604';
}
.icon-sunrise:before {
  content: '\e605';
}
.icon-noon:before {
  content: '\e606';
}
.icon-ticket:before {
  content: '\e94e';
}
.icon-tumblr2:before {
  content: '\eabc';
}
.icon-happy-face:before {
  content: '\e979';
}
.icon-smiley-face:before {
  content: '\e97a';
}
.icon-neutral-face:before {
  content: '\e97b';
}
.icon-sad-face:before {
  content: '\e97c';
}
.icon-frown-face:before {
  content: '\e97d';
}
