@import '~@getvim/components-base-css/src/bookmd-import';

//Global default text
body {
  font-family: 'proximaNova', Helvetica, Arial, sans-serif;
  color: @bookmd-text-color;
  font-size: 15px;
}

//Font colors
.text-strong-dark {
  color: @bookmd-text-dark-color !important;
  font-weight: bold;
}

.text-strong-danger {
  color: @bookmd-error-color !important;
  font-weight: bold;
}

.text-strong-orange {
  color: @bookmd-providers-orange-color !important;
  font-weight: bold;
}

.text-strong-success {
  color: @bookmd-providers-green !important;
  font-weight: bold;
}

.text-strong-faded {
  color: @bookmd-providers-light-grey !important;
  font-weight: bold;
}

.text-light-blue {
  color: @bookmd-light-blue-color !important;
}

.text-light-blue-dark {
  color: @bookmd-light-blue-color-dark !important;
}

.text-strong-light-blue {
  color: @bookmd-light-blue-color !important;
  font-weight: bold;
}

.text-strong-main {
  color: @bookmd-main-color !important;
  font-weight: bold;
}

.text-orange {
  color: @bookmd-providers-orange-color;
}

.text-success {
  color: @bookmd-providers-green !important;
}

.text-faded {
  color: @bookmd-providers-light-grey;
}

.text-danger {
  color: @bookmd-error-color;
}

.text-dark {
  color: @bookmd-text-dark-color;
}

.text-extra-dark {
  color: @bookmd-providers-very-dark-grey !important;
}

.text-ultra-dark {
  color: @bookmd-text-dark-color-2 !important;
}

.text-main {
  color: @bookmd-main-color;
}

.text-light-blue {
  color: @bookmd-light-blue-color !important;
}

.text-white {
  color: #fff !important;
}

.text-pink {
  color: @bookmd-pink-color;
}

.text-pink-dark {
  color: @bookmd-dark-pink-color;
}

.text-theme-secondary {
  color: var(--secondary-color);
}

.text-theme-main {
  color: var(--main-color);
}

.text-semibold {
  font-weight: @semibold !important;
}

.text-no-line-breaks {
  white-space: nowrap;
}

//Font weight
.text-medium {
  font-weight: @medium !important;
}

.text-light {
  font-weight: @light !important;
}

.text-black {
  font-weight: @black !important;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.text-normal {
  font-weight: normal !important;
}

.text-strong {
  font-weight: bold !important;
}

//Font size
.text-24 {
  font-size: 24px;
}

.text-22 {
  font-size: 22px;
}

.text-18 {
  font-size: 18px;
}

.text-16 {
  font-size: 16px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

//Titles
.title-24 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.title-30 {
  font-size: 30px;
}

.title-36 {
  font-size: 36px;
}

.title-48 {
  font-size: 48px;
}

.title-24,
.title-36,
.title-48 {
  &.md-margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &.margin-30 {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  &.margin-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.margin-13 {
  margin: 13px;
}

//White space
.text-nowrap {
  white-space: nowrap;
}

//Ellipsis
.three-dots {
  .three-dots();
}

//Text decoration
.text-line-through {
  text-decoration: line-through;
}

// HR
hr {
  border-top: 1px solid @bookmd-border-color;
}

//a
a {
  color: inherit;

  &:active,
  &:hover,
  &:focus,
  &:visited {
    color: inherit;
    text-decoration: none;
  }
}

//H1
h1 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  line-height: normal;
}

h2 {
  font-size: inherit;
  line-height: inherit;
}
