/* TODO: Make this file support theme- not trivial due to psuedoselectors
         details- https://bookmd.atlassian.net/browse/SAM-13811 */
.pagination.pagination-lg.vim-pagination {
  .page-item {
    color: #32333c;

    .page-link {
      width: 36px;
      height: 36px;
      line-height: 36px;
      padding: 0;
      font-size: 12px;
      text-align: center;
      font-weight: normal;
      border-color: #7f96ab;
    }

    &.active {
      color: #fff;

      .page-link {
        background: #045393;
        border-color: #045393;
      }
    }
  }

  & > li:first-child > a,
  & > li:first-child > span {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & > li:first-child > a {
    font-size: 0 !important;

    &:before {
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 6px 5px 0;
      border-color: transparent #7f96ab transparent transparent;
      position: relative;
      top: 4px;
    }
  }

  & > li.vim-pagination-next > a,
  & > li.vim-pagination-next > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 0 !important;

    &:before {
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 6px;
      border-color: transparent transparent transparent #7f96ab;
      position: relative;
      top: 4px;
    }
  }
}
