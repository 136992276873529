//ProximaNova
@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_12_0.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_10_0.ttf') format('truetype');
  font-weight: 100; /*// light //*/
}

@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_11_0.ttf') format('truetype');
  font-weight: 500; /*// medium //*/
}

@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_F_0.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.eot');
  src: url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/proximaNova/2F97CE_13_0.ttf') format('truetype');
  font-weight: 600; /*// semibold //*/
}

// Lora
@font-face {
  font-family: 'lorabold';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lorabold_italic';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'loraitalic';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'loraregular';
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.eot');
  src: url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/lora/lora-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Roboto
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.woff') format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-100.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.woff') format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-300.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-500.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-700.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
    url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.woff') format('woff'),
    /* Modern Browsers */
      url('~@getvim/components-base-css/src/fonts/roboto/roboto-v20-latin-900.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

// Gotham
@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 200;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLight_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLight_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 200;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLightItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-XLightItalic_Web.woff')
      format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 300;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Light_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Light_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 300;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-LightItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-LightItalic_Web.woff')
      format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 400;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Book_Web.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Book_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 400;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BookItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BookItalic_Web.woff')
      format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 500;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Medium_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Medium_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 500;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-MediumItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-MediumItalic_Web.woff')
      format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 700;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Bold_Web.woff2') format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Bold_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 700;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BoldItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BoldItalic_Web.woff')
      format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 800;
  font-style: normal;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Black_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-Black_Web.woff') format('woff');
}

@font-face {
  font-family: 'HCo Gotham SSm';
  font-weight: 800;
  font-style: italic;
  src: url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BlackItalic_Web.woff2')
      format('woff2'),
    url('~@getvim/components-base-css/src/fonts/gotham/GothamSSm-BlackItalic_Web.woff')
      format('woff');
}

// Brownhill
@font-face {
  font-family: 'Brownhill';
  src: url('~@getvim/components-base-css/src/fonts/brownhill/Brownhill.woff') format('woff'),
    url('~@getvim/components-base-css/src/fonts/brownhill/Brownhill.ttf') format('woff2');
}

/* source-sans-pro-200 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-200italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-200italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-300italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-600italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-700italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900italic - cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('~@getvim/components-base-css/src/fonts/sourceSansPro/source-sans-pro-v14-cyrillic-900italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'SF Pro Text Regular';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Regular.woff');
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Regular.woff2')
}

@font-face {
  font-family: 'SF Pro Text Medium';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Medium.woff');
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Medium.woff2')
}

@font-face {
  font-family: 'SF Pro Text Bold';
  src: url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Bold.woff');
  src: local(''),
  url('~@getvim/components-base-css/src/fonts/sfProText/SF Pro Text Bold.woff2')
}

