@import '~@getvim/components-base-css/src/bookmd-import';

//Margin
.margin-bottom-clean-form {
  margin-bottom: 40px;
}
.margin-bottom {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-v-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.margin-top {
  margin-top: 15px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-right-20 {
  margin-right: 20px;
}

.full-width-flow-step {
  margin-left: -30px;
  margin-right: -30px;
}

// Padding
.no-padding {
  padding: 0 !important;
}

.no-v-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.md-no-padding {
  @media (min-width: @screen-md) {
    padding: 0 !important;
  }
}

.padding-box-30 {
  padding: 30px;
}

.padding-box-20 {
  padding: 20px;
}

.padding-box-10 {
  padding: 10px;
}

.padding-v-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-v-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-h-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-h-30-important {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.padding-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}

//Blue link
.blue-link {
  color: @bookmd-light-blue-color !important;
  cursor: pointer;

  &:hover {
    color: @bookmd-providers-blue !important;
    text-decoration: none;
  }
}

// For cursored components
.default-cursor-override {
  cursor: default;
}

//Underline link
.underline-link {
  color: @bookmd-text-color;
  text-decoration: underline;
}

//Long words
.break-long-words {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

//Responsive grid helpers
.show {
  opacity: 1 !important;
}

.hide {
  opacity: 0;
}

.equal-cols {
  display: table;

  .cell-col {
    display: table-cell;
    float: none;
  }
}

.equal-flex-cards {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0 30px;

  & > div {
    width: calc(33.33% - 30px);
    margin: 0 15px 30px 15px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles */
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    }

    & > .card {
      box-shadow: none;
    }
  }

  &.md-blocks {
    @media (max-width: @screen-lg) {
      flex-direction: column;
      align-items: center;
      margin: 0 0 10px 0;

      & > div {
        width: 50%;
      }
    }

    @media (max-width: @screen-sm-no-ipad) {
      & > div {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }

  &.auto-width {
    & > div {
      width: auto;
      flex: 1;
    }
  }

  &.horizontal-list {
    flex-direction: column;

    & > div {
      width: 100% !important;
      margin: 0 0 10px 0;

      .card-body {
        flex-direction: row;
      }
    }
  }
}

.xs-block {
  @media (max-width: @screen-sm) {
    display: block !important;
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

.hidden-1040 {
  @media (max-width: 1040px) {
    display: inherit;
  }

  @media (min-width: 1040px) {
    display: none;
  }
}

.hidden-xxs {
  @media (max-width: @screen-xxs) {
    display: none;
  }
}

.visible-1040 {
  @media (max-width: 1040px) {
    display: none;
  }

  @media (min-width: 1040px) {
    display: inherit;
  }
}

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.sm-pull-left {
  @media (min-width: @screen-sm) {
    float: left;
  }
}

.sm-pull-right {
  @media (min-width: @screen-sm) {
    float: right;
  }
}

//ROWS & COLS
.row {
  &.small-gutter {
    margin-right: -5px;
    margin-left: -5px;

    & > div {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &.tiny-gutter {
    margin-right: -2px;
    margin-left: -2px;

    & > div {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.col-7 {
  width: 14.28%;
  float: left;
}

.col-5 {
  width: 20%;
  float: left;
}

.clear-floats {
  clear: both;
}

//Global layout
html,
body {
  height: 100%;
}

.container.container-1040 {
  max-width: 1040px;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: @screen-sm) {
    width: 100%;
  }
}

.content-grey-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: @header-height-members 0 0 0;
  background: @bookmd-background-color-1;
  overflow-x: hidden;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  z-index: -1;

  @media (min-width: @screen-sm) {
    padding: @header-height-members-lg 0 0 0;
  }
}

//Icons
.i-va-fix-2 {
  vertical-align: -2px;
}

.i-va-fix-3 {
  vertical-align: -3px;
}

//Bg colors
.bg-main-color {
  background: @bookmd-main-color;
  color: #fff;

  h2,
  h3,
  h4 {
    color: #fff;
  }
}

.bg-grey-color {
  background-color: @bookmd-background-color-1 !important;
}

.bg-providers-grey-color {
  background-color: @bookmd-providers-light-grey !important;
}

.bg-light-blue-color-dark {
  background: @bookmd-light-blue-color-dark;
}

.bg-light-blue-color {
  background: @bookmd-light-blue-color;
}

.bg-orange-color {
  background: @bookmd-providers-orange-color !important;
}

.bg-success,
.bg-success-color {
  background: @bookmd-green-color;
}

.bg-pink,
.bg-pink-color {
  background: @bookmd-pink-color;
}

.bg-providers-success-color {
  background: @bookmd-providers-green;
}

.bg-providers-pink-color {
  background: @providers-pink-color;
}

.bg-providers-orange-color {
  background: @case-need-more-info;
}

.bg-providers-error-color {
  background: @bookmd-error-color !important;
}

.bg-mild-grey-color {
  background: @bookmd-text-color !important;
}

.bg-transperent,
.bg-transparent {
  background-color: transparent !important;
}

.no-border {
  border-width: 0px;
}

//HR
.or-hr {
  .or-text {
    display: inline-block;
    padding: 0 10px;
    margin: 10px 0;
    background: #fff;
    z-index: 2;
    position: relative;
  }

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    margin: -1px 0 0 0;
    background: @bookmd-border-color;
    z-index: 1;
  }
}

.half-opacity {
  opacity: 0.5;
}

//SVG SIZE
.svg-32 {
  & > .svg-icon {
    height: 32px;
    width: auto;
  }
}

.svg-16 {
  & > .svg-icon {
    height: 16px;
    width: auto;
  }
}

.svg-72 {
  & > .svg-icon {
    height: 72px;
    width: auto;
  }
}

//Borders
.right-border {
  border-right: 1px solid @bookmd-border-color;

  @media (max-width: @screen-sm-no-ipad) {
    border-right: none;
    border-bottom: 1px solid @bookmd-border-color;
  }
}

.left-border {
  border-left: 1px solid @bookmd-border-color;

  @media (max-width: @screen-sm-no-ipad) {
    border-left: none;
    border-top: 1px solid @bookmd-border-color;
  }
}

//Accessibility
.off-screen-text {
  position: absolute;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  width: 1px;
  line-height: 1px;
  margin: 0;
}
//Performance
.gpu-render {
  transform: translateZ(0);
}

//Hide shadow
.no-shadow {
  box-shadow: none !important;
}
