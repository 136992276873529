@import '~@getvim/components-base-css/src/bookmd-import';

//Basic dropdown
.basic-dropdown,
.dropdown {
  display: inline-block;
  position: relative;

  //.basic-dropdown-trigger {
  //  width: 100%;
  //  padding: 0 70px 0 10px;
  //  cursor: pointer;
  //
  //  &:before {
  //    content: "\e90f";
  //    right: 0;
  //    font-family: 'bookmd_icons';
  //    font-size: 12px;
  //    color: @bookmd-text-dark-color;
  //    .vertical-align-middle(absolute);
  //  }
  //
  //  &.tooltip-icon {
  //    display: inline-block;
  //    padding: 8px 30px 8px 10px;
  //    margin-top: -10px;
  //
  //    &:before {
  //      right: 10px;
  //    }
  //
  //    i {
  //      vertical-align: -2px;
  //      margin-left: 0;
  //    }
  //
  //    & + .basic-dropdown-menu.dropdown-menu {
  //      right: -2px;
  //    }
  //  }
  //}

  .dropdown-toggle {
    .caret {
      border: none;
      height: auto;
      width: auto;
      line-height: 14px;

      &:after {
        display: inline-block;
        content: '\e90f';
        font-family: 'bookmd_icons';
        font-size: 10px;
        margin: 0 0 0 5px;
      }
    }
  }

  .dropdown-menu {
    left: auto;
    right: -10px;
    min-width: 100%;
    //margin-top: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    border-color: @bookmd-border-color;

    & > li {
      & > a {
        line-height: 36px;
        font-size: 16px;
        padding: 0 20px;
        color: @bookmd-text-color;

        &:hover,
        &:active,
        &:focus {
          color: #fff;
        }
      }

      &.small-screens-only {
        @media (min-width: 769px) {
          display: none;
        }
      }

      &.dropdown-header {
        width: 100% !important;
        height: @header-height-members;
        line-height: @header-height-members;
        padding: 0 @header-height-members;
        text-align: left;
        position: relative;

        a {
          padding: 0 !important;
          line-height: @header-height-members;

          &:hover,
          &:active,
          &:focus {
            background: #fff;
          }
        }
      }

      &.selected {
        & > a {
          box-shadow: 6px 0 0 @bookmd-light-blue-color inset;
        }
      }
    }

    &:before,
    &:after {
      bottom: 100%;
      left: auto;
      right: 10px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 8px;
    }

    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-bottom-color: @bookmd-border-color;
      border-width: 10px;
      right: 8px;
    }
  }

  &.xs-fullscreen-dropdown {
    .dropdown-toggle {
      @media (max-width: @screen-sm-no-ipad) {
        .caret {
          display: none;
        }
      }
    }

    .dropdown-menu {
      & > li > a {
        min-height: 56px;
        line-height: 56px;
        color: @case-past-emergency;
        font-size: 18px;

        & > i,
        & > .svg-icon,
        & > a > i {
          font-size: 19px;
          display: inline-block;
          vertical-align: -2px;
          margin: 0 15px 0 0;
          height: 16px;
          width: 20px;
        }

        & > a {
          display: inline-block;
          width: 100%;
        }

        &:hover,
        &:active,
        &:focus {
          color: #fff;

          .svg-icon {
            .fill-color-brand-main {
              fill: #fff !important;
            }

            .fill-color-brand-secondary {
              fill: #fff !important;
            }
          }
        }
      }

      @media (max-width: @screen-sm-no-ipad) {
        position: fixed;
        width: calc(~'100% - ' 40px) !important;
        height: 100%;
        overflow: auto;
        top: 0;
        left: 0;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none;
        border: none;

        & > li {
          &.dropdown-header {
            a {
              line-height: @header-height-members;
              height: @header-height-members;
            }
          }

          > a {
            min-height: 48px;
            line-height: 48px;
            font-size: 18px;
          }
        }

        .dropdown-back-btn,
        .dropdown-close-btn {
          display: block;
          position: absolute;
          top: 0;
          font-size: 20px;
          text-align: center;
          color: @bookmd-text-dark-color;
          line-height: @header-height-members;
          height: @header-height-members;
          width: @header-height-members;

          &.left {
            left: 0;
          }

          &.right {
            right: 0;
          }
        }
      }
    }
  }

  &.scrollable {
    &.open {
      .dropdown-toggle {
        z-index: 2000;

        &:before,
        &:after {
          bottom: -14px;
          left: auto;
          right: -3px;
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          z-index: 2000;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 8px;
        }

        &:before {
          border-color: rgba(204, 204, 204, 0);
          border-bottom-color: @bookmd-border-color;
          border-width: 10px;
          right: -5px;
        }

        &:hover {
          z-index: 2000;
        }
      }
    }

    .dropdown-menu {
      max-height: 400px;
      overflow: auto;
    }
  }

  &.open {
    .dropdown-toggle {
      box-shadow: none !important;
    }
  }

  //&.arrow-center {
  //  .basic-dropdown-trigger {
  //    padding: 0;
  //
  //    &:before {
  //      display: none;
  //    }
  //  }
  //
  //  .basic-dropdown-menu.dropdown-menu {
  //    .horizontal-align-middle;
  //    width: 175px;
  //    right: auto;
  //
  //    &:before, &:after {
  //      right: 50%;
  //    }
  //
  //    &:after {
  //      margin-right: -7px;
  //    }
  //
  //    &:before {
  //      margin-right: -9px;
  //    }
  //  }
  //
  //}
  //
  //&.hover-blue-items {
  //  .basic-dropdown-menu.dropdown-menu {
  //    padding: 4px 0;
  //
  //    & > li {
  //      //height: 44px;
  //      color: @bookmd-text-color;
  //
  //      a {
  //        color: @bookmd-text-color;
  //      }
  //
  //      &.divider {
  //        margin: 4px 0;
  //      }
  //
  //      &:hover {
  //        background: @bookmd-light-blue-color;
  //        color: #fff;
  //
  //        & > a {
  //          color: #fff;
  //        }
  //      }
  //
  //      &.grey {
  //        background: @bookmd-background-color-1;
  //        box-shadow: 0 4px 0 @bookmd-background-color-1, 0 -4px 0 @bookmd-background-color-1;
  //
  //        &:hover {
  //          background: @bookmd-background-color-1;
  //
  //          a {
  //            color: @bookmd-text-color;
  //            box-shadow: none;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
  //
  //&.consumer-help-dropdown {
  //  position: absolute;
  //  right: 0;
  //  top: 0;
  //  height: 30px;
  //
  //  .basic-dropdown-trigger {
  //
  //    &:before {
  //      display: none;
  //    }
  //  }
  //
  //  .dropdown-menu {
  //    right: 9px;
  //    padding: 0;
  //
  //    li {
  //      border-bottom: 1px solid @bookmd-border-color;
  //      color: @bookmd-text-color;
  //
  //      & > a {
  //        padding: 8px 10px;
  //        color: @bookmd-text-color;
  //      }
  //
  //      i {
  //        font-size: 27px;
  //        vertical-align: -4px;
  //        padding: 0 10px 0 0;
  //
  //        &.icon-chat {
  //          font-size: 25px;
  //          vertical-align: -6px;
  //        }
  //      }
  //
  //      .chat-time {
  //        font-size: 12px;
  //        display: block;
  //        padding: 0 0 5px 38px;
  //        line-height: 10px;
  //        margin-top: -4px;
  //      }
  //
  //      &:last-child {
  //        border-bottom: none;
  //
  //        i {
  //          font-size: 20px;
  //        }
  //      }
  //    }
  //  }
  //  .welcome-wrap & {
  //
  //    @media (min-width: 1040px) {
  //      top: 8px;
  //    }
  //  }
  //}
  //
  //&.provider-help-dropdown {
  //  //position: absolute;
  //  //right: 0;
  //  //top: 0;
  //  //height: 30px;
  //
  //  .basic-dropdown-trigger {
  //    padding: 0;
  //
  //    &:before {
  //      display: none;
  //    }
  //  }
  //
  //  .basic-dropdown-menu.dropdown-menu {
  //    right: 0;
  //  }
  //}

  &.pill-btn-dropdown {
    .btn.dropdown-toggle {
      border-radius: 50px;
      font-weight: @semibold;
      font-size: 16px;
      line-height: 36px;
      height: 36px;
      color: @bookmd-text-dark-color;
      text-align: left;
      padding: 0 35px 0 10px;
      .three-dots();

      &:before {
        content: '\e90f';
        right: 10px;
        font-family: 'bookmd_icons';
        font-size: 12px;
        color: @bookmd-text-dark-color;
        .vertical-align-middle(absolute);
      }
    }

    .dropdown-menu {
      padding: 5px 0;

      li {
        a {
          line-height: normal;
          padding: 10px 20px;

          .item-title {
            color: @bookmd-text-dark-color;
          }

          &:active,
          &:hover,
          &:focus {
            .item-title {
              color: #fff;
            }
          }
        }

        &:hover {
          a {
            background: none;
          }
        }
      }
    }

    &.open {
      .btn.dropdown-toggle {
        background: @bookmd-providers-card-grey-title-color;
      }
    }
  }

  &.left-border-menu {
    .dropdown-menu {
      li {
        line-height: 36px;

        a {
          min-height: 36px;
          line-height: 36px;

          &:active,
          &:hover,
          &:focus {
            background: #fff;
            color: @bookmd-providers-very-dark-grey;
          }
        }

        &.active {
          a {
            background: #fff;
            color: @bookmd-providers-very-dark-grey;
          }
        }

        @media (max-width: @screen-sm-no-ipad) {
          padding: 6px 0;

          &.dropdown-header {
            padding: 0 0 0 @header-height-members;
          }
        }
      }
    }
  }
}
