@import '~@getvim/components-base-css/src/bookmd-import';

.popover {
  border: 1px solid @bookmd-border-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 1px;
  font-family: 'proximaNova', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;

  .arrow {
    top: 100%;

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #ffffff transparent transparent transparent;
      top: -11px;
      left: -10px;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -11px;
      left: -12px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 12px;
      border-color: @bookmd-border-color transparent transparent transparent;
    }
  }
}
