@import '~@getvim/components-base-css/src/bookmd-import.less';

.landing-page-header {
  margin-top: 150px;
  margin-bottom: 10px;

  .landing-page-main-title {
    font-weight: @black !important;

    @media (max-width: @screen-sm) {
      font-size: 24px;
    }
  }
}

.landing-page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
