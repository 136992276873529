@import '~@getvim/components-base-css/src/bookmd-import';

//Progress Bar
.progress {
  box-shadow: none;

  &.loading-progress {
    height: 10px;
    border-radius: 8px;
    background: @bookmd-payment-steps-blue-color;

    .progress-bar {
      border-radius: 0 8px 8px 0;
      background: @bookmd-light-blue-color;
    }
  }

  &.small-progress {
    height: 6px;
    border-radius: 0;
    box-shadow: none;
    background: @bookmd-providers-light-grey;
  }

  &.trans-progress {
    background: none;
    height: 22px;

    .progress-bar.progress-bar-stripes {
      background: url('~@getvim/components-base-css/src/images/stripes-trans-bg.png') repeat;
    }
  }

  .progress-bar {
    box-shadow: none;

    &.progress-bar-warning {
      background: @bookmd-progress-orange-color;
    }

    &.progress-bar-success {
      background: @bookmd-green-color;
    }
  }

  &.brand-color-progress {
    background: @bookmd-providers-light-grey;
  }
}

.progress-wrap {
  &.success {
    .progress {
      background: @bookmd-green-color;
    }

    .progress-bar {
      background: @bookmd-green-color;
    }

    .progress-text {
      color: @bookmd-green-color;
    }
  }

  &.error {
    .progress {
      background: @bookmd-error-color;
    }

    .progress-bar {
      background: @bookmd-error-color;
    }

    .progress-text {
      color: @bookmd-error-color;
    }
  }

  &.warning {
  }
}
