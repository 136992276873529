@import '~@getvim/components-base-css/src/bookmd-import';

.bootstrap-typeahead {
  &.dashed-border-typeahead {
    .bootstrap-typeahead-input-main {
      padding: 0;
      border-width: 0 0 1px 0;
      border-radius: 0;
      border-style: dashed;
      box-shadow: none;
      height: 30px;
      line-height: 30px;
      font-size: 24px;
    }
  }

  &.show-caret {
    .bootstrap-typeahead-input-main {
      padding-right: 20px;
    }

    &:before {
      display: inline-block;
      content: '\e90f';
      font-family: 'bookmd_icons';
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 2;
      font-size: 10px;
    }
  }
}
