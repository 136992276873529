@import '~@getvim/components-base-css/src/bookmd-import';

.CircularProgressbar {
  width: 100%;

  .CircularProgressbar-trail {
    stroke: @bookmd-border-color;
  }

  .CircularProgressbar-text {
    fill: @bookmd-light-blue-color;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar-path {
    stroke: @bookmd-light-blue-color;
    stroke-linecap: round;
    transition: stroke-dashoffset 2s ease 0s;
  }

  .CircularProgressbar-text {
    fill: @bookmd-light-blue-color;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  &.hide-Percentage {
    .CircularProgressbar-text {
      display: none;
    }
  }

  &.trans-bg {
    .CircularProgressbar-trail {
      stroke: rgba(0, 0, 0, 0.1);
    }
  }

  &.white-path {
    .CircularProgressbar-path {
      stroke: #fff;
    }
  }

  &.light-blue-path {
    .CircularProgressbar-path {
      stroke: @bookmd-light-blue-color;
    }
  }
}
