@import '~@getvim/components-base-css/src/bookmd-import';
// TODO better way is to make component for big search input and load the less file from there
@import './bigSearchInput.less';

.clean-input,
.clean-input.Select > .Select-control {
  box-shadow: none;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid @bookmd-border-color;
  height: 35px;
  border-radius: 0 !important;
  margin: 0 0 64px 0;
  position: relative;
  padding: 0;
  font-size: 16px;
  z-index: 2;
  background: none;
  width: 100%;

  &.margin-45 {
    margin-bottom: 45px;
  }

  &.margin-30 {
    margin-bottom: 30px;
  }

  &:disabled {
    color: @bookmd-border-color;
  }

  & + label {
    position: absolute;
    font-weight: normal;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 35px;
    margin-bottom: 0;
    font-size: 14px;
    color: @bookmd-text-color;
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
    z-index: 1;

    &.input-active {
      color: @bookmd-text-color;
      font-size: 11px;
      font-weight: @medium;
      top: -30px;
    }
  }

  & ~ .input-right-icon {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 7px;
    font-size: 20px;
    color: @bookmd-text-dark-color;
    border: none;
    background-color: Transparent;
    .transition(all 200ms ease);

    &:hover {
      opacity: 1;
    }
  }

  &.clean-text-area {
    min-height: 35px !important;
    line-height: 35px;

    & + label + .help-message {
      top: auto;
      bottom: 25px;
    }
  }

  &:focus,
  & > .Select-control:active {
    box-shadow: none !important;

    & + label {
      color: @bookmd-text-dark-color;
      font-size: 11px;
      font-weight: @medium;
      top: -30px;
    }

    & ~ .input-right-icon {
      opacity: 1;
    }
  }

  & > option:first-child {
    font-size: 14px;
    color: darken(@bookmd-border-color, 5%);
  }

  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0;

    & ~ .help-message {
      opacity: 1;
      height: auto;
    }
  }

  .dashed-border-typeahead & {
    &.Select {
      border-bottom: none;
    }

    .Select-control,
    .Select-control:active {
      padding: 0;
      border-width: 0 0 1px 0 !important;
      border-radius: 0;
      border-style: dashed !important;
      box-shadow: none;
      line-height: 30px;
      font-size: 18px;

      .Select-input,
      .Select-value-label {
        height: 30px;
        line-height: 30px;
        padding: 0;
        font-size: 18px !important;

        & > input {
          line-height: 30px;
          padding: 0;
          height: 30px;
        }
      }

      .Select-value {
        padding-right: 20px !important;
      }
    }
  }

  &.show-caret {
    .bootstrap-typeahead-input-main {
      padding-right: 20px;
    }

    &:before {
      display: inline-block;
      content: '\e90f';
      font-family: 'bookmd_icons';
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 2;
      font-size: 10px;
    }
  }
}

.clean-input.Select-control,
.bootstrap-typeahead {
  z-index: 2;
}

.clean-input.Select,
.bootstrap-typeahead {
  z-index: auto;

  &.has-value > .Select-control .Select-value {
    padding-right: 40px;
    padding-left: 0;

    .Select-value-label {
      color: @bookmd-main-color;
      font-size: 16px;
      display: inline-block;
      max-width: 100%;
      position: relative;
      .three-dots();
    }
  }

  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
  }

  &.is-focused,
  &.is-open {
    & + label {
      color: @bookmd-text-color;
      font-size: 11px;
      font-weight: @medium;
      top: -30px;
    }
  }

  .Select-value {
    padding-left: 0;
  }

  .Select-clear-zone {
    color: @bookmd-text-dark-color;
  }

  &.is-disabled {
    min-height: 0;
    padding: 0;
    pointer-events: none;
    .Select-value-label {
      color: @bookmd-providers-light-grey !important;
    }

    .title-option {
      height: 24px;
      background: @bookmd-lighter-blue-color;
      padding: 0 15px;
      font-size: 14px;
      line-height: 24px;
      min-height: 24px;
      pointer-events: none;
    }

    & > .Select-control {
      background: none;
    }
  }

  &.grey-select {
    background: @bookmd-background-color-1;

    .Select-control {
      .Select-value {
        .Select-value-label {
          font-weight: @semibold;
          color: @bookmd-text-color;
        }
      }
    }
  }

  .Select-placeholder {
    padding: 0;
    font-size: 16px;
  }

  &.is-open > .Select-control,
  &.is-focused > .Select-control {
    z-index: 3;
    border-bottom: 2px solid @bookmd-main-color;

    .Select-control {
      border-bottom: 2px solid @bookmd-main-color;
      box-shadow: none;
      outline: none;
    }

    .Select-input {
      padding: 0;

      & > input {
        padding: 0;
        margin-top: 5px;
      }
    }

    &.is-focused {
    }

    & + label {
      color: @bookmd-text-dark-color;
      font-size: 11px;
      font-weight: @medium;
      top: -30px;
    }

    .Select-arrow {
      border-color: transparent;
      border-width: 0 0 0 0;
      top: 0;
    }
  }

  &.has-value.is-pseudo-focused > .Select-control .Select-value .Select-value-label {
    color: @bookmd-main-color;
  }

  .Select-arrow {
    border-color: transparent;
    border-width: 0 0 0 0;
    cursor: pointer;

    &:after {
      display: inline-block;
      content: '\e90f';
      font-family: 'bookmd_icons';
      font-size: 13px;
      color: @bookmd-text-dark-color;
    }
  }

  .Select-clear-zone {
    .Select-clear {
      font-size: 0;

      &:after {
        display: block;
        content: '\e60f';
        font-family: 'bookmd_icons';
        color: @bookmd-text-dark-color;
        font-size: 12px;
      }
    }
  }

  .Select-menu-outer {
    margin-top: 10px;
    border-radius: 0 !important;
    border: none;
    z-index: 20;
    //float: left;
  }

  .Select-menu,
  .dropdown-menu {
    border-radius: 0 !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    max-width: 100%;
    width: 100%;

    //@media (min-height: @screen-sm) {
    //  max-height: 700px;
    //}
  }

  .Select-option,
  .dropdown-menu > li > a {
    font-size: 16px;
    padding: 12px 15px;
    min-height: 44px;
    line-height: 20px;
    color: @bookmd-text-color;
    background: #fff;

    .highlight {
      background: @bookmd-providers-light-blue-hover;
      font-weight: normal;
      padding: 2px 0;
    }

    .profile-img-option {
      padding: 0 0 0 50px;
      height: 36px;
      min-height: 36px;
      position: relative;

      &.hide-img {
        padding: 0;
      }

      .select-option-rounded-img {
        position: absolute;
        top: -2px;
        left: 0px;
        //border: 2px solid @bookmd-text-color;

        .content {
          font-size: 16px;
          text-align: center;
          display: block;
        }
      }

      .option-info {
        display: block;
        line-height: 16px;
        font-size: 14px;
        color: @bookmd-text-color;
        .three-dots();
      }

      .option-name {
        display: block;
        font-size: 16px;
        line-height: 18px;
        font-weight: @medium;
        color: @bookmd-text-dark-color;
        .three-dots();
      }

      &.tiny {
        height: auto;
        min-height: 32px;

        .option-name {
          line-height: 32px;
        }

        .select-option-rounded-img {
          top: 0;
        }
      }

      .pic-wrap {
        position: absolute;
        left: 0;
        width: 40px;
        height: 40px;
        display: inline-block;
      }
    }

    .hide-img,
    .title-option {
      .select-option-rounded-img {
        padding: 0;
        display: none;
      }
    }

    &:hover,
    &:active,
    &.is-focused,
    &:hover > a,
    &:focus > a,
    &:active > a {
      color: #fff;

      .highlight {
        background: rgba(181, 230, 247, 0.3);
      }

      .option-info {
        color: #fff;
      }

      .option-name {
        color: #fff;
      }
    }

    @media (max-width: @screen-sm) {
      &:hover {
        background: none !important;
        color: @bookmd-text-color !important;

        .highlight {
          background: @bookmd-providers-light-blue-hover !important;
        }

        .option-info {
          color: @bookmd-text-color !important;
        }

        .option-name {
          color: @bookmd-text-color !important;
        }
      }
    }
  }

  .dropdown-menu > li {
    &.no-results-select-option {
      a {
        pointer-events: none;
        white-space: normal;
        text-align: center;
        font-size: 18px;
        padding: 60px 50px;
        font-weight: @light;

        img,
        .svg-icon {
          width: 72px;
          height: auto;
          margin: 0 0 20px 0;
        }

        &:hover,
        &:focus,
        &:hover > a,
        &:focus > a {
          background: #fff;
          color: @bookmd-text-color;
        }
      }
    }
  }

  .left-label-icon & {
    .Select-placeholder,
    .Select-input,
    .Select-value {
      padding-left: 30px;
    }

    &.has-value > .Select-control .Select-value {
      padding-left: 30px;
    }
  }

  .bootstrap-typeahead-input-main.form-control {
    color: @bookmd-text-dark-color;
    font-weight: @medium;
    font-size: 16px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @placeholder-color;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: @placeholder-color;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: @placeholder-color;
    }

    @media (min-width: @screen-sm) {
      //margin: 1px 0 0 0;
      border-radius: 5px;
    }
  }

  .spinner {
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 2px 0 0 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    padding: 10px 0 4px 0;
    position: relative;
    z-index: 20;

    .svg-loader {
      display: inline-block;
    }
  }
}

.select-left-icon {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 19px;
  color: @bookmd-text-color;
}

label.static-label-header {
  line-height: 35px;
  font-size: 16px;
  color: @bookmd-text-color;
  font-weight: @medium;
}

.form-group {
  position: relative;
  margin-bottom: 0;
}

.checkbox-position {
  padding-top: 7px;
  min-width: 56px;
}

.date-component {
  .error-wrap {
    position: relative;
  }

  .past-date-message.validation-message {
    position: relative;
    bottom: 35px;
    display: inline-block;
    top: auto;
    width: auto;
    .horizontal-align-middle();
  }
}

// input with validation message style
.validation-error,
.has-error {
  color: @bookmd-error-color;

  .clean-input,
  .clean-select + .select2-container {
    border-bottom: 1px solid @bookmd-error-color;
  }

  .validation-message {
    display: block;
  }

  &.warning {
    color: @bookmd-text-color;

    .clean-input,
    .clean-select + .select2-container,
    .clean-input.Select > .Select-control {
      border-bottom: 1px solid @case-need-more-info;
    }
  }

  &.top {
    .validation-message {
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      font-weight: @semibold;
      padding: 10px 0;
      top: -35px;

      &:after {
        border-width: 5px 5px 0 5px;
        border-color: #b12704 transparent transparent transparent;
        top: auto;
        bottom: -5px;
      }
    }
  }
}

// validation message
.validation-message {
  display: none;
  font-size: 14px;
  position: absolute;
  top: 42px;
  color: #fff;
  font-weight: @semibold;
  background: @bookmd-error-color;
  line-height: 13px;
  width: 100%;
  padding: 7px;
  z-index: 10;
  border-radius: 4px;
  text-align: center;
  font-weight: @semibold;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #b12704 transparent;
  }

  &:before {
    content: 'Error: ';
    display: inline;
  }

  .warning & {
    background: @case-need-more-info;

    &:after {
      border-color: transparent transparent @case-need-more-info transparent;

      .top & {
        border-color: @case-need-more-info transparent transparent transparent;
      }
    }
  }

  .warning.top & {
    &:after {
      border-color: @case-need-more-info transparent transparent transparent;
    }
  }
}

.textarea-limit {
  top: -40px;
  position: relative;
}

.validationField-height {
  height: 80px;
}

// input help message
.help-message {
  opacity: 0;
  display: block;
  position: absolute;
  top: 45px;
  left: 0;
  font-size: 14px;
  height: 0;
  overflow: hidden;
  .transition(all 300ms ease);

  .relative-help-text & {
    position: relative;
    top: 5px;
  }
}

.clean-typeahead {
  position: relative;
}

input.clean-input::-ms-clear {
  display: none;
}

input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::-moz-placeholder {
  font-size: 16px;
}
