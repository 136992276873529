@import '~@getvim/components-base-css/src/bookmd-import.less';

.header.provider-discovery-widget-header {
  position: absolute;
  width: auto;
  height: 110px;
  padding: 0 0 0 40px;
  background: none;
  .transition(all 300ms ease-out);

  .header-left {
    line-height: 110px;
  }

  & + div {
    padding-top: 0;
  }

  @media (max-width: @screen-sm) {
    height: 50px;
    padding: 0 0 0 15px;
  }
}

.base-layout.provider-discovery {
  position: relative;
}
